import React, {useState, useEffect, FunctionComponent} from "react";
import {initializeApp, auth} from "firebase/app";
import "./App.scss";
import DonationPortal from "./pages/DonationPortal";
import Admin from "./pages/Admin";
import "firebase/auth";
import LinearProgress from '@material/react-linear-progress';
import User from "../entities/User";
import functions from "./services/functions";
import {getLoadingSetter} from "./LoadingSetter";
import config from "./config";

const {authEmulatorHost, firestoreHost} = config;

const firebaseConfig = {
  apiKey: "AIzaSyB97IhsBHCSV0CJxfLSrM6b-ysR4IZEQh4",
  authDomain: "endowment-fmusp.firebaseapp.com",
  databaseURL: "https://endowment-fmusp.firebaseio.com",
  projectId: "endowment-fmusp",
  storageBucket: "endowment-fmusp.appspot.com",
  messagingSenderId: "824546242508",
  appId: "1:824546242508:web:536fcc1db54fc95ad97769"
};

const firebase = initializeApp(firebaseConfig);
firebase.firestore().settings({
  ignoreUndefinedProperties: true,
  host: firestoreHost,
  ssl: firestoreHost ? false : undefined,
});
if (authEmulatorHost) {
  firebase.auth().useEmulator(authEmulatorHost);
}
firebase.auth().setPersistence(auth.Auth.Persistence.LOCAL);



const App: FunctionComponent = () => {
  const isAdminArea = window.location.pathname === "/admin";

  const [firebaseUser, setFirebaseUser] = useState(firebase.auth().currentUser);
  const [user, setUser] = useState<User | null>(null);
  const loadingState = useState<string[]>([]);
  const loadingTasks = loadingState[0];
  const setLoading = getLoadingSetter(loadingState);


  firebase.auth().onAuthStateChanged(async user => {
    setFirebaseUser(user);
    functions.token = await user?.getIdToken();
  });

  useEffect(() => {
    if (firebaseUser !== null) {
      const collection = firebase.firestore().collection("users");
      collection.doc(firebaseUser.uid).get().then(doc => {
        const userData = doc.data();
        if (userData) {
          setUser(userData as User);
        } else {
          firebase.auth().signOut();
          setUser(null);
        }
      });
    } else {
      setUser(null);
    }
  }, [firebaseUser !== null ? firebaseUser.uid : null]);

  return(
    <div className="l-app">
      {isAdminArea
        ? <Admin
            firebase={firebase}
            firebaseUser={firebaseUser}
            setLoading={setLoading}
            loading={loadingTasks}
          />
        : <DonationPortal
          loading={loadingTasks}
          setLoading={setLoading}
          firebase={firebase}
          firebaseUser={firebaseUser}
          user={user}
          setUser={setUser}
        />
      }
      {loadingTasks.length > 0
        ? <LinearProgress className="l-loading-bar" indeterminate={true}/>
        : ""
      }
    </div>
  );
}

export default App;
