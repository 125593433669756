import React, {FunctionComponent, ReactComponentElement} from "react";
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from '@material/react-dialog';

export interface WarningDialogProps {
  onClose?: () => void | Promise<void>;
  isOpen: boolean;
  message: string | ReactComponentElement<any, any>;
  isError?: boolean;
}

const WarningDialog: FunctionComponent<WarningDialogProps> = (props) => {
  const {onClose, isOpen, message, isError} = props;

  return <>
    <Dialog
      className={isError
        ? "s-error-state-dialog"
        : "s-default-state-dialog"
      }
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle>Alerta</DialogTitle>
      <DialogContent>
        {typeof message === "string"
          ? <p>{message}</p>
          : message 
        }
      </DialogContent>
      <DialogFooter>
        <DialogButton outlined={true} action="accept" isDefault>Fechar</DialogButton>
      </DialogFooter>
    </Dialog>
  </>
}

export default WarningDialog;