import React, {useState, FunctionComponent, useRef, MutableRefObject, PureComponent, useEffect} from "react";
import { app } from "firebase";
import LoadingSetter from "../LoadingSetter";
import { useReactToPrint } from "react-to-print";
import { Button } from "@material/react-button";
import MaterialIcon from "@material/react-material-icon";
import User from "../../entities/User";
import Donation, { DonationStatus } from "../../entities/Donation";
import { Payment } from "../../entities/Payment";
import "./DonationsQuery.scss";
import TextField, { Input } from "@material/react-text-field";

export interface DonationsQueryProps {
  firebase: app.App;
  setLoading: LoadingSetter;
  cancelDonationHandler: () => void;
  user: User | null;
  activeIndexHandler?: (indx: 0 | 1) => void;
  donation?: Partial<Donation>;
  setDonation?: (donation: Partial<Donation>) => void;
}


interface ReceiptProps {
  ref: MutableRefObject<any>;
  user: User;
  year: string;
  donations: Donation[];
}

const statusNamesPtBr = new Map<DonationStatus, string>([
  ["PAYMENT_PENDING", "Pendente"],
  ["PAYMENT_DONE", "Confirmada"],
  ["CANCELED", "Cancelada"],
]);


const getPaymentLabel = (payment: Payment): string => {
  let label: string = "Método de pagamento não identificado";
  if (payment.type === "BOLETO") {
    label = "Boleto bancário";
  } else if (payment.type === "CREDIT_CARD") {
    const {company, last4digits} = payment;
    const hasCompany = typeof company === "string";
    const hasLast4digits = typeof last4digits === "string"; 
    if (hasCompany && hasLast4digits) {
      label = `Crédito - ${company?.toUpperCase()} final ${last4digits}`;
    } else {
      console.error("Unable to get credit card label", payment);
      label = "Cartão de crédito";
    }
  }

  return label;
}


class Receipt extends PureComponent<ReceiptProps> {
  render() {
    const {user, ref, year, donations} = this.props;
    let totalAmount = 0;

    return <div className="receipt" ref={ref}>
      <h3>Comprovante de doações - FMUSP</h3>
      <p>Dados do doador:</p>
      <ul>
        <li>Nome/Razão social: {user.name}</li>
        <li>CPF/CNPJ: {user.document}</li>
      </ul>
      <p>Período:</p>
      <ul>
        <li>De: 01/01/{year}</li>
        <li>Até: 31/12/{year}</li>
      </ul>

      <p>Doações feitas:</p>

      <table>
          <tr>
            <th>Data</th>
            <th>Forma de Pagamento</th>
            <th className="l-amount-column">Valor</th>
            <th>Status</th>
          </tr>
          {donations.map(donation => {
            if (donation.status === "PAYMENT_DONE") {
              totalAmount += donation.amount;
            }
            return <tr>
              <td>{(new Date(donation.date)).toLocaleDateString()}</td>
              <td>{getPaymentLabel(donation.payment)}</td>
              <td>R$ {donation.amount.toLocaleString("pt-BR")}</td>
              <td>{statusNamesPtBr.get(donation.status)}</td>
            </tr>
          })}
          <tr>
            <td></td>
            <td style={{textAlign: "right"}}><strong>Total Doado:</strong></td>
            <td>R$ {totalAmount.toLocaleString("pt-BR")}</td>
            <td></td>
          </tr>
      </table>
    </div>
  }
}

const DonationsQuery: FunctionComponent<DonationsQueryProps> = (props) => {
  const {
    firebase,
    user,
    setLoading,
    cancelDonationHandler,
    activeIndexHandler,
    donation,
    setDonation,
  } = props;
  const [yearInput, setYearInput] = useState<string>((new Date()).getFullYear().toString());
  const [year, setYear] = useState<string>((new Date()).getFullYear().toString());
  const [donations, setDonations] = useState<Donation[]>([]);

  const printComponentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    documentTitle: "Comprovante de doações - FMUSP",
    pageStyle: "margin: 20pt;",
    onBeforePrint: () => setLoading("print"),
    onAfterPrint: () => setLoading("print", false),
    onPrintError: () => setLoading("print", false),
  });
  const fetchDonations = () => {
    if (user !== null) {
      const collection = firebase.firestore().collection(`users/${user.id}/donations`);
      setLoading("donations-query");
      collection
        .where("date", ">=", (new Date(year)).toISOString())
        .where("date", "<=", (new Date(`${year}-12-31T23:59:59.999Z`)).toISOString())
        .limit(100)
        .get()
        .then(docs => {
          const data: Donation[] = [];
          docs.forEach(doc => data.push(doc.data() as Donation));
          setDonations(data);
        })
        .catch(err => {
          console.error(err);
        }).finally(() => {
          setLoading("donations-query", false);
        });
    }
  }

  useEffect(fetchDonations, [`${year}-${user ? user.id : ""}`]);

  return <>
    <ul>
      <li>Ano:
        <TextField className="l-year-input" outlined={true}>
          <Input
            isValid={new Number(yearInput) > 2019}
            value={yearInput}
            onChange={(e: any) => {
              const value = e.currentTarget.value as string;
              if (
                value.length <= 4 &&
                (value === "" || !isNaN((new Number(value)).valueOf())) &&
                value.startsWith("20")
                ) {
                setYearInput(value);
              }
            }}
          ></Input>
        </TextField>
        <Button
          outlined={true}
          trailingIcon={<MaterialIcon icon="search" role="button" />}
          onClick={() => {
            if (new Number(yearInput) > 2000) {
              setYear(yearInput);
            }
          }}
        >Buscar</Button>
      </li>
    </ul>

    {user !== null && donations.length > 0 && <>
      <Receipt ref={printComponentRef} user={user} year={year} donations={donations}/>
    
      <Button
        className="m-centralize l-print-button"
        trailingIcon={<MaterialIcon icon="print" role="button" />}
        onClick={handlePrint}
      >Imprimir</Button>
    </>}

    {donations.length === 0 &&
      <p>Nenhuma doação feita em {year}</p>
    }

    {user?.isMonthlySubscribed && <div className="l-subscription-change-button-container">
      
      {
      typeof activeIndexHandler !== "undefined" &&
      typeof donation !== "undefined" &&
      typeof setDonation !== "undefined" &&
        <Button
          className="l-change-doantion-button m-centralize"
          onClick={() => {
            setDonation({
              ...donation,
              amount: user.monthlySubscription?.amount || 75,
              type: "MONTHLY",
            })
            activeIndexHandler(0);
          }}
        >Alterar doações mensais</Button>
      }
      <Button
        className="l-cancel-subscription-button m-centralize"
        onClick={cancelDonationHandler}
      >Cancelar doações mensais</Button>
    </div>}
  </>
}

export default DonationsQuery;