import React from "react";
import "./Divider.scss";

export default class Divider extends React.PureComponent {
  constructor(props: any) {
    super(props);
  }

  render() {
    return <div className="m-divider" />
  }
}