import React, {useState, FunctionComponent, useEffect, ReactElement} from "react";
import Password from "./Password";
import { app } from "firebase";
import { Button } from "@material/react-button";
import Login, {LoginData, LoginButton} from "../../components/forms/Login";
import "./Manage.scss";
import MaterialIcon from "@material/react-material-icon";
import Donation, { DonationStatus } from "../../../entities/Donation";
import { ErrorCode } from "../../Result";
import LoadingSetter from "../../LoadingSetter";
import { ActionToCofirm } from "../../components/ConfirmationDialog";
import functions from "../../services/functions";
import { AxiosError } from "axios";
import DonationsQuery from "../../components/DonationsQuery";
import User from "../../../entities/User";
import Card from "@material/react-card";
import CreditCards from "../../components/forms/Updates/CreditCards";
import UserDataUpdate from "../../components/forms/Updates/UserDataUpdate";
import TabBar, { Tab } from "@material/react-tab-bar";
import {getMonthName} from "../../monthNames";

type MainDialogOption =
  | "default"
  | "update-user-data"
  | "donations-query";

const mainDialogTitlePtBr = new Map<MainDialogOption, string>([
  ["default", "Avisos"],
  ["update-user-data", "Meus dados"],
  ["donations-query", "Gerenciar Doações"],
])

interface ManageProps {
  firebase: app.App;
  isPasswordConfig: boolean | null;
  resetPasswordCode: string | null;
  loading: string[];
  setLoading: LoadingSetter;
  user: User | null;
  setUser: (user: User | null) => void;
  actionToConfirm: ActionToCofirm;
  setActionToConfirm: (action: ActionToCofirm) => void;
  errorToDisplay: ErrorCode | null;
  setErrorToDisplay: (error: ErrorCode | null) => void;
  activeIndexHandler: (indx: 0 | 1) => void;
  donation: Partial<Donation>;
  setDonation: (donation: Partial<Donation>) => void;
  setIsLoginResetDialogOpen: (isOpen: boolean) => void;
  setSuccessfulPasswordReset: (success: boolean) => void;
}


const Manage: FunctionComponent<ManageProps> = (props) => {
  const {
    firebase,
    isPasswordConfig,
    resetPasswordCode,
    loading,
    setLoading,
    user,
    setUser,
    setActionToConfirm,
    setErrorToDisplay,
    activeIndexHandler,
    donation,
    setDonation,
    setIsLoginResetDialogOpen,
    setSuccessfulPasswordReset,
  } = props;
  const currentUser = firebase.auth().currentUser;
  const [loginData, setLoginData] = useState<LoginData>({
    email: "",
    password: "",
  });
  const [pendingDonations, setPendingDonations] = useState<Donation[] | null>(null);
  const displayName = currentUser?.displayName ? currentUser.displayName : "";
  
  const [mainDialogOption, setMainDialogOption] = useState<MainDialogOption>("default");
  const [userDataActiveIndex, setUserDataActiveIndex] = useState<number>(0);

  const fetchPendingDonations = () => {
    if (currentUser?.uid) {
      const collection = firebase.firestore().collection(`users/${currentUser.uid}/donations`);
      setLoading("pending-donations-fetch");
      collection
        .where("status", "==", "PAYMENT_PENDING" as DonationStatus)
        .limit(100)
        .get()
        .then(docs => {
          const data: Donation[] = [];
          docs.forEach(doc => data.push(doc.data() as Donation));
          setPendingDonations(data);
        })
        .catch(err => {
          console.error(err);
        }).finally(() => {
          setLoading("pending-donations-fetch", false);
        });
    }
  }

  const getPaymentMethodNameOrLink = (donation: Donation): ReactElement<any, any> => {
    switch(donation.payment.type) {
      case "BOLETO":
        return <a href={donation.pdfUrl}>Boleto bancário</a>;
      case "CREDIT_CARD":
         return <span>Cartão de crédito</span>;
      default:
        return <span>Erro</span>
    }
  }

  const missingFieldsPtBr: string[] = [];
  if (!user?.phone) {
    missingFieldsPtBr.push("número de telefone");
  }
  if (!user?.address) {
    missingFieldsPtBr.push("endereço");
  }

  const getNextDonationMonthName = (): string | undefined => {
    const now = new Date();
    const currentMonth = now.getMonth();
    const lastDonationDate = user?.monthlySubscription?.lastDonationDate;
    const isFailing = (user?.monthlySubscription?.failCount || 0) > 0;

    const nextDonationMonth = ((): number => {
      if (isFailing) {
        return currentMonth;
      } else {
        return (
          lastDonationDate &&
          new Date(lastDonationDate).getMonth() === currentMonth
        )
          ? currentMonth + 1
          : currentMonth;
      }
    })();

    return getMonthName(nextDonationMonth);
  };

  const getNextDonationDay = (): string => {
    const paymentDay = user?.monthlySubscription?.paymentDay;
    if (!paymentDay) {
      return "?";
    }
    const isFailing = (user?.monthlySubscription?.failCount || 0) > 0;
    return (isFailing ? new Date().getDate() : paymentDay).toString();
  }

  useEffect(() => {
    fetchPendingDonations();
  }, [currentUser?.uid]);

  const getPendingDonationRow = (donation: Donation): ReactElement => <tr>
    <td>{getPaymentMethodNameOrLink(donation)}</td>
    <td>R$ {donation.amount.toFixed(2)}</td>
    <td>
      <Button
        icon={<MaterialIcon icon="cancel"/>}
        disabled={donation.type === "MONTHLY"}
        onClick={() => {
          const callback = async () => {
            setLoading("cancel-donation");
            try {
              await functions.cancelDonation(donation.id, donation.userId);
            } catch(err) {
              if (err.isAxiosError) {
                const axiosError = err as AxiosError;
                if([401, 403].includes(
                  axiosError.response?.status as number
                )) {
                  setErrorToDisplay("auth-fail");
                } else {
                  setErrorToDisplay("internal-error");
                }
              } else if (err.isMissingToken) {
                setErrorToDisplay("auth-fail");
              }
            } finally {
              setLoading("cancel-donation", false);
              fetchPendingDonations();
            }
          };
          setActionToConfirm({
            confirmText: "Cancelar",
            cancelText: "Fechar",
            title: "Deseja realmente cancelar a doação?",
            callback,
          });
        }}
      ></Button>
    </td>
  </tr>

  const cancelDonationHandler = () => {
    setActionToConfirm({
      title: "Deseja realmente cancelar sua doação mensal? Também é possível alterá-la na aba Doe.",
      confirmText: "Cancelar doação",
      cancelText: "Fechar",
      callback: async () => {
        const updates: Partial<User> = {
          isMonthlySubscribed: false,
        }
        setLoading("subscription-delete");
        try {
          if (user === null) {
            setErrorToDisplay("auth-fail");
            setLoading("subscription-delete", false);
            return;
          }
          await firebase.firestore().collection("users").doc(user.id).update(updates);
          setUser({
            ...user,
            ...updates,
          });
        } catch(err) {
          console.error(err);
          setErrorToDisplay("internal-error");
        } finally {
          setLoading("subscription-delete", false);
        }
      },
    });
  }

  return (isPasswordConfig
    ? <Password
        code={resetPasswordCode}
        firebase={props.firebase}
        setLoading={setLoading}
        setErrorToDisplay={setErrorToDisplay}
        setSuccessfulPasswordReset={setSuccessfulPasswordReset}
      />
    :
      <>
        {currentUser && <>
          {displayName && <h3>Bem vindo, {displayName}</h3>}
          <p>Atualizar ou consultar:</p>
          <Button
            className="m-main-actions-button"
            outlined={mainDialogOption === "default"}
            onClick={() => setMainDialogOption("default")}
          >{mainDialogTitlePtBr.get("default")}</Button>
          <Button
            className="m-main-actions-button"
            outlined={mainDialogOption === "update-user-data"}
            onClick={() => setMainDialogOption("update-user-data")}
          >{mainDialogTitlePtBr.get("update-user-data")}</Button>
          <Button
            className="m-main-actions-button"
            outlined={mainDialogOption === "donations-query"}
            onClick={() => setMainDialogOption("donations-query")}
          >{mainDialogTitlePtBr.get("donations-query")}</Button>

          <Card className="l-card">
            <h3 className="l-card-title">{mainDialogTitlePtBr.get(mainDialogOption)}</h3>
            <div className="l-card-content">
              {mainDialogOption === "donations-query" &&
                <DonationsQuery
                  firebase={firebase}
                  setLoading={setLoading}
                  user={user}
                  cancelDonationHandler={cancelDonationHandler}
                  activeIndexHandler={activeIndexHandler}
                  donation={donation}
                  setDonation={setDonation}
                />
              }

              {mainDialogOption === "update-user-data" && <>
                <TabBar
                  className="l-tab-bar"
                  activeIndex={userDataActiveIndex}
                  handleActiveIndexUpdate={setUserDataActiveIndex}
                >
                  <Tab>
                    <span className="mdc-tab__text-label">Cadastro</span>
                  </Tab>
                  <Tab>
                    <span className="mdc-tab__text-label">Cartões de crédito</span>
                  </Tab>
                </TabBar>
                {userDataActiveIndex === 0 &&
                  <UserDataUpdate
                    firebase={firebase}
                    user={user}
                    setUser={setUser}
                    errorHandler={setErrorToDisplay}
                    setLoading={setLoading}
                  />
                }
                {userDataActiveIndex === 1 &&
                  <CreditCards
                    firebase={firebase}
                    user={user as User}
                    setUser={setUser}
                    errorHandler={setErrorToDisplay}
                    setLoading={setLoading}
                    setActionToConfirm={setActionToConfirm}
                  />
                }
              </>}

              {user?.isMonthlySubscribed && <div className="m-highlight-info">
                <p>Você possui uma doação mensal ativa:</p>
                <ul>
                  <li>Próximo lançamento no dia {getNextDonationDay()} de {getNextDonationMonthName()}</li>
                  {user?.monthlySubscription?.guild && 
                    <li>Você está destinando parte das suas doações para "{user.monthlySubscription.guild}"</li>
                  }
                  <li>Para mais detalhes, consulte a seção "GERENCIAR DOAÇÕES"</li>
                </ul>
                </div>
              }

              {mainDialogOption === "default" &&
                pendingDonations instanceof Array && pendingDonations.length > 0 && <div className="m-highlight-info l-pending-donations-table-container">
                  <p>Doações pendentes:</p>
      
                  <table id="pending-donations-table">
                      <tr>
                        <th>Forma de pagamento</th>
                        <th>Valor</th>
                        <th>Ações</th>
                      </tr>
                      {pendingDonations.map(getPendingDonationRow)}
                  </table>
                </div>
              }

              {mainDialogOption === "default" &&
                pendingDonations instanceof Array && pendingDonations.length === 0 && <>
                  <p>Você não possui nenhuma doação pendente.</p>
                </>
              }
              
              {mainDialogOption === "default" && missingFieldsPtBr.length > 0 && <>
                <p>Por favor, complete seu cadastro com os campos:</p>
                <ul>
                  {missingFieldsPtBr.map(field => <li>{field}</li>)}
                </ul>
              </>}

            </div>
          </Card>

          <Button
            className="m-login-logout-button"
            outlined={true}
            onClick={async () => {
              setLoading("logout");
              await firebase.auth().signOut();
              setLoginData({
                email: "",
                password: ""
              });
              setLoading("logout", false);
            }}
          >
            Logout
          </Button>          
        </>}

        {!currentUser && <>
          <h3>Gerencie suas doações</h3>

          <Login loginData={loginData} setLoginData={setLoginData}/>

          <a className="l-reset-password-a" href="#login-reset-dialog" onClick={async (e) => {
            setIsLoginResetDialogOpen(true);
          }}>Esqueci minha senha</a>

          <LoginButton
            className="m-login-logout-button"
            firebase={firebase}
            loginData={loginData}
            loading={loading}
            setLoading={setLoading}
            errorHandler={(code) => {
              switch(code) {
                case "auth/invalid-email":
                  setErrorToDisplay("invalid-email");
                  return;
                case "auth/user-not-found":
                  setErrorToDisplay("no-user-to-email");
                  return;
                case "auth/wrong-password":
                  setErrorToDisplay("wrong-password");
                  return;
                default:
                  setErrorToDisplay("login-fail");
                  return;
              }
            }}
          />
        </>}
      </>
  );
}

export default Manage;