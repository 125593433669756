type LoadingSetter = (taskId: string, on?: boolean) => void;
export type LoadingState = [string[], (taskIds: string[]) => void];

let _loading: string[] = [];

export function getLoadingSetter(state: LoadingState): LoadingSetter {
  const [loading, setLoading] = state;

  return (taskId, on=true) => {
    _loading = on ? [..._loading, taskId] : _loading.filter(id => id !== taskId);
    setLoading(_loading);
  }
}

export default LoadingSetter;
