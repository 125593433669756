import { Button } from "@material/react-button";
import { Chip } from "@material/react-chips";
import MaterialIcon from "@material/react-material-icon";
import TextField, { Input } from "@material/react-text-field";
import { app } from "firebase";
import LoadingSetter from "LoadingSetter";
import { FC, FormEvent, useEffect, useState } from "react";

export interface GuildsProps {
  firebase: app.App;
  loading: string[];
  setLoading: LoadingSetter;
}

const Guilds: FC<GuildsProps> = ({
  firebase,
  loading,
  setLoading,
}) => {
  const [guilds, setGuilds] = useState<string[] | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const doc = firebase.firestore().collection("config").doc("guilds");

  useEffect(() => {
    setLoading("loading-guilds");
    doc.get().then(
      snapshot => {
        const data = snapshot.data();
        
        setGuilds(data?.options instanceof Array
          ? data.options
          : []
        );
      }
    ).catch(err => {
      console.error(err);
      alert("Não foi possível carregar as agremiações");
    }).finally(() => {
      setLoading("loading-guilds", false);
    });
  }, []);

  const onSave = async () => {
    setLoading("guild-save");
    try {
      await doc.update({ options: guilds ?? [] })
      alert("Agremiações salvas com sucesso");
    } catch(err) {
      console.error(err);
      alert("Não foi possível salvar");
    } finally {
      setLoading("guild-save", false);
    }
  }

  const onGuildAdd = () => {
    setGuilds((lastValues =>
      [...new Set([...(lastValues ?? []), inputValue.trim()])].filter(Boolean)
    ));
    setInputValue("");
  };
  
  const isSaveEnabled = !loading.length && guilds;

  return <>
    {guilds &&
      <ul style={{listStyle: "none"}}>
        {guilds.map(guild => <>
          <li key={guild}>
            <Chip
              style={{margin: "5px"}}
              label={guild}
              trailingIcon={
                <MaterialIcon
                  icon="delete"
                  onClick={() => setGuilds(
                    (lastValue) => lastValue && lastValue.filter(v => v !== guild)
                  )} 
                />
              }
            />
          </li>
        </>)}
        <li>
          <TextField style={{width: "50ch", margin: "20px"}}>
            <Input
              value={inputValue}
              onKeyUp={(e: any) => {
                if (e.keyCode === 13) {
                  e.preventDefault();
                  document.getElementById("add-guild-button")?.click();
                }
              }}
              onChange={(e: FormEvent<HTMLInputElement>) => {
                setInputValue(e.currentTarget.value);
              }}
            />
          </TextField>
          <Button
            id="add-guild-button"
            onClick={onGuildAdd} 
            style={{display: "inline"}}
          >Adicionar</Button>
        </li>
      </ul>
    }

    <Button
      style={{marginTop: "40px"}}
      outlined
      onClick={onSave}
      disabled={!isSaveEnabled}
    >Salvar</Button>
  </>
}

export default Guilds;
