import React, { FunctionComponent } from "react";
import { app } from "firebase";
import LoadingSetter from "../../LoadingSetter";
import { AdminPage, AdminPageNamesPtBr } from "../../components/AdminDrawer";
import Donnors from "./Donnors";
import CRM from "./CRM";
import Dashboard from "./Dashboard";
import Guilds from "./Guilds";
import SetCampaign from "./SetCampaign";

interface AdminPageContentProps {
  firebase: app.App;
  setLoading: LoadingSetter;
  loading: string[];
  adminPage: AdminPage;
}

const AdminPageContent: FunctionComponent<AdminPageContentProps> = (props) => {
  const { firebase, setLoading, loading, adminPage } = props;

  return <div className="l-admin-page-content">
    <div className="l-admin-page-content-container">
      <h1>{AdminPageNamesPtBr.get(adminPage)}</h1>

      {(() => {
        switch (adminPage) {
          case "donnors":
            return <Donnors firebase={firebase} loading={loading} setLoading={setLoading} />
          case "campaigns":
            return <SetCampaign firebase={firebase} loading={loading} setLoading={setLoading} />
          case "crm":
            return <CRM firebase={firebase} loading={loading} setLoading={setLoading} />
          case "guilds":
            return <Guilds firebase={firebase} loading={loading} setLoading={setLoading} />
          default:
            return <Dashboard firebase={firebase} loading={loading} setLoading={setLoading} />
        }
      })()}
    </div>
  </div>
}

export default AdminPageContent;