import axios, {AxiosRequestConfig, AxiosError} from "axios";
import {DonationCreationRequest, DonationCancelRequest} from "../../entities/Donation";
import config from "./../config";

export interface CreditCardFunctionResponse {
  junoId: string;
}

const initialHeaders: any = {};


class Functions {
  private axiosConfig: AxiosRequestConfig;
  private _token: string | null;

  constructor() {
    this.axiosConfig = {headers: initialHeaders};
    this._token = null;
  }

  async newDonation(donationData: DonationCreationRequest): Promise<string | undefined> {
    if (this._token || donationData.userId === "anonymous") {
      const response = await axios.post<DonationCreationRequest, any>(
        `${config.functionsUrl}/donation`,
        {
          ...donationData,
          message: donationData.message && donationData.message.replaceAll("<", "").replaceAll(">", ""),
        },
        this.axiosConfig,
      );

      return response?.data?.boletoUrl;
    } else {
      const err = new Error("Missing auth token");
      (err as any).isMissingToken = true;
      throw(err);
    }
  }

  async cancelDonation(donationId: string, userId: string): Promise<void> {
    await axios.delete<DonationCancelRequest>(
      `${config.functionsUrl}/donation`,
      {
        ...this.axiosConfig,
        data: {id: donationId, userId},
      }
    );
  }

  async addCreditCard(hash: string): Promise<string> {
    if (this._token) {
      const response = await axios.post<CreditCardFunctionResponse>(
        `${config.functionsUrl}/creditCard`,
        {creditCardHash: hash},
        this.axiosConfig,
      );
      const junoId = response.data?.junoId;
  
      if (typeof junoId !== "string") {
        throw(new Error("Missing credit card id"));
      } else {
        return junoId;
      }
    } else {
      const err = new Error("Missing auth token");
      (err as any).isMissingToken = true;
      throw(err);
    }
  }

  async conflictCheck(document: string): Promise<boolean> {
    try {
      const response = await axios.get(
        `${config.functionsUrl}/conflictCheck?document=${document}`
      );

      if (response.status === 200) {
        return false;
      } else {
        throw(new Error("Invalid document"));
      }
    } catch(err) {
      err = err as AxiosError;
      if (err?.response?.status === 409) {
        return true;
      } else {
        throw(err);
      }
    }
  }

  set token(token: string | null | undefined) {
    if (token) {
      this.axiosConfig.headers["Authorization"] = `Bearer ${token}`;
      this._token = token;
    } else {
      this.axiosConfig.headers = initialHeaders;
      this._token = null;
    }
  }
}

const functions = new Functions();

export default functions;
