import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { toBrlValue } from '../helpers';

const TextBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 80px;
`
const MainText = styled(Typography)`
    font-size: 30px;
    font-weight: 700;
    color: #35b0b8;
    margin-left: 5px;
    margin-right: 5px;
`
const SecondaryText = styled(Typography)`
    font-size: 20px;
    font-weight: 300;
    color: gray;
    margin-left: 5px;
    margin-right: 5px;;
`
export default function AllTimeAmount(props: any) {

    return (
        <TextBox>
            <SecondaryText>Total Arrecadado:</SecondaryText>
            <MainText>{toBrlValue(props.allTimeAmount)}</MainText>
        </TextBox>
    )
}