import React, { useState, FunctionComponent, useEffect, useRef } from "react";
import { app, User as FirebaseUser } from "firebase/app";
import "./DonationPortal.scss";
import Manage from "./Manage";
import NewDonation from "./NewDonation";
import Tab from "@material/react-tab";
import TabBar from "@material/react-tab-bar";
import Donation from "../../../entities/Donation";
import User from "../../../entities/User";
import LoadingSetter from "../../LoadingSetter";
import ConfirmationDialog, { ActionToCofirm } from "../../components/ConfirmationDialog";
import WarningDialog from "../../components/WarningDialog";
import { ErrorCode, getErrorMessagePtBr } from "../../Result";
import { StepName } from "./StepChangePreations";
import { LoginResetDialog } from "../../components/forms/Login";
import { PrivacyPolicyDialog } from "../../components/PrivacyPolicy";
import HorizontalBarChart from "../../components/HorizontalBarChart";
import { Box, Typography } from "@mui/material";
import ClassesRankingBarChart from '../../components/ClassesRankingBarChart'
import AllTimeAmount from '../../components/AllTimeAmount';

interface DonationPortalProps {
  firebase: app.App;
  firebaseUser?: FirebaseUser | null;
  user: User | null;
  setUser: (user: User | null) => void;
  loading: string[];
  setLoading: LoadingSetter;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface Dictionary<T> {
  [Key: string]: T;
}

export class SearchParameters {
  SearchFor: Dictionary<number> = {};
}

class CampaignSchema {
  constructor(readonly name: string,
    readonly begin_date: string,
    readonly end_date: string,
    readonly goal_amount: number,
    readonly is_monthly_goal: Boolean,
    readonly timestamp: string
  ) { }
}

const campaignConverter = {
  toFirestore(campaign: CampaignSchema): firebase.firestore.DocumentData {
    return {
      name: campaign.name,
      begin_date: campaign.begin_date,
      end_date: campaign.end_date,
      goal_amount: campaign.goal_amount,
      timestamp: campaign.timestamp
    };

  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): CampaignSchema {
    const data = snapshot.data(options)!;
    return new CampaignSchema(data.name, data.begin_date, data.end_date, data.goal_amount, data.is_monthly_goal, data.timestamp);
  }
};

const assetsUrl = "assets";
const logoUrl = `${assetsUrl}/logo.png`;

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const DonationPortal: FunctionComponent<DonationPortalProps> = (props) => {
  const { firebase, firebaseUser, user, setUser, setLoading, loading } = props;

  const searchParams = new URLSearchParams(window.location.search);
  const resetPasswordCode = searchParams.get("oobCode");
  const isPasswordConfig = typeof resetPasswordCode === "string" &&
    window.location.pathname.startsWith("/redefinir-senha");

  const [donation, setDonation] = useState<Partial<Donation>>({
    amount: 100,
    type: "MONTHLY",
    paymentDay: 5
  });

  const [errorToDisplay, setErrorToDisplay] = useState<ErrorCode | null>(null);
  const [actionToConfirm, setActionToConfirm] = useState<ActionToCofirm>(null);
  const isManagePage = isPasswordConfig || window.location.pathname.startsWith("/area-do-doador");
  const [activeIndex, setActiveIndex] = useState<number>(isManagePage ? 1 : 0);
  const activeIndexHandler = (index: number): void => {
    if (!isPasswordConfig) {
      setActiveIndex(index);
    }
  };
  const [currentStep, setCurrentStep] = useState<StepName>("amount");
  const [isLoginResetDialogOpen, setIsLoginResetDialogOpen] = useState<boolean>(false);
  const [isPrivacyPolicyDialogOpen, setIsPrivacyPolicyDialogOpen] = useState<boolean>(false);
  const [successfulDonation, setSuccessfulDonation] = useState<boolean>(false);
  const [successfulPasswordReset, setSuccessfulPasswordReset] = useState<boolean>(false);
  const [errorDetails, setErrorDetails] = useState<string[]>([]);
  const [currentCampaignAmount, setCurrentCampaignAmount] = useState<number>(0.0);

  const [allTimeAmount, setAllTimeAmount] = useState<number>(0.0);
  const [hasLoadedCampaign, setHasLoadedCampaign] = useState<boolean>(false);
  const [classesRankDataClean, setClassesRankDataClean] = useState<Object[]>([]);

  const [start_date, setStartDate] = useState<string>("");
  const [campaignTitle, setCampaignTitle] = useState<string>("");
  const [isMonthlyGoal, setIsMonthlyGoal] = useState<Boolean>(false);

  const [goalAmount, setGoalAmount] = useState<number>(1);
  const [end_date, setEndDate] = useState<string>("")
  const [remainingDays, setRemainingDays] = useState<number>(0)
  const [progress, setProgress] = useState<number>(0)

  const currentCampaignSum = useRef(0);
  const currentSum = useRef(0);
  const newClassesRankDataClean = useRef<Object[]>([]);

  function isPaid(donation: any) {
    return (donation.status === 'PAYMENT_DONE');
  }

  const [campaign, setCampaign] = useState<CampaignSchema>(new CampaignSchema("", "", "", 0, false, ""));
  useEffect(() => {
    setLoading("campaign-fetch");
    const campaignsCollection = firebase.firestore().collection("campaigns");
    campaignsCollection
      .withConverter(campaignConverter)
      .orderBy("timestamp", "desc")
      .get()
      .then(snapshot => {
        const campaigns = snapshot.docs.map(doc => (doc.data()));
        setCampaign(campaigns[0] as unknown as CampaignSchema);

      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading("campaign-fetch", false);
        setHasLoadedCampaign(true);
      });

  }, []);


  useEffect(() => {
    if (campaign !== undefined) {
      const { name, begin_date, end_date, goal_amount, is_monthly_goal } = campaign
      setStartDate(begin_date)
      setCampaignTitle(name);
      setGoalAmount(goal_amount * 1.0);
      setEndDate(end_date);
      setIsMonthlyGoal(is_monthly_goal);
      const end = new Date(end_date)
      const diffTime = Math.round(end.getTime() - Date.now());
      setRemainingDays(Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
    }
  }, [hasLoadedCampaign]);


  useEffect(() => {
    setLoading("campaign-data-fetch");
    function isPaidAndBelongToCurrentCampaign(donation: any, start: string, end: string) {
      //  console.log((donation.status === 'PAYMENT_DONE' && donation.date.slice(0, 10) <= end && donation.date.slice(0, 10) >= start))
      return (donation.status === 'PAYMENT_DONE' && donation.date.slice(0, 10) <= end && donation.date.slice(0, 10) >= start);
    }

    function isPaidAndBelongToCurrentRecurringCampaign(donation: any, existingMonthlyDonatingUsers: string[]) {
      //  console.log((donation.status === 'PAYMENT_DONE' && donation.date.slice(0, 10) <= end && donation.date.slice(0, 10) >= start))
      const date = new Date()
      const firstDayPrevMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1).toISOString();
      const firstDayCurrMonth = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();

      const lastDayPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0).toISOString();
      const expectedMonthlyDonatorFromPreviousDonation = (donation.status === 'PAYMENT_DONE' && donation.type === 'MONTHLY' && donation.date.slice(0, 10) <= lastDayPrevMonth && donation.date.slice(0, 10) >= firstDayPrevMonth)
      if (expectedMonthlyDonatorFromPreviousDonation) {
        existingMonthlyDonatingUsers.push(donation.userId)
      }
      const newMonthlyDonator = (donation.status === 'PAYMENT_DONE' && donation.type === 'MONTHLY' && donation.date.slice(0, 10) >= firstDayCurrMonth && !existingMonthlyDonatingUsers.includes(donation.userId))
      return newMonthlyDonator;
    }
    const userCollection = firebase.firestore().collection("users");

    const classesRankData = new SearchParameters();
    let possibleClasses: string[] = []
    let existingMonthlyDonatingUsers: string[] = []
    classesRankData.SearchFor["-1"] = 0


    userCollection
      .get()
      .then(snapshot => {
        const users = snapshot.docs.map(doc => (doc.data()));
        for (let user of users) {
          let currentClass = "-1"
          if (user.year) {

            if (classesRankData.SearchFor[user.year] === undefined) {
              classesRankData.SearchFor[user.year] = 0
              possibleClasses.push(user.year)
            }
            currentClass = user.year
          }
          const userDonations = firebase.firestore().collection(`users/${user.id}/donations`);
          userDonations
            .get()
            // eslint-disable-next-line no-loop-func
            .then(snapshot => {
              const donations = snapshot.docs.map(doc => (doc.data()));
              let currentUserSum = donations.filter(isPaid).reduce((prev, { amount }) => {
                return prev + amount
              }, 0)

              currentSum.current += currentUserSum / 2;
              classesRankData.SearchFor[currentClass] = classesRankData.SearchFor[currentClass] + currentUserSum
              let currentUserCurrentCampaignSum: number = 0
              if (isMonthlyGoal) {
                for (let i = 0; i < donations.length; i++) {
                  if (isPaidAndBelongToCurrentRecurringCampaign(donations[i], existingMonthlyDonatingUsers)) {
                    currentUserCurrentCampaignSum = currentUserCurrentCampaignSum + donations[i].amount
                  }
                }
              }
              else {
                for (let i = 0; i < donations.length; i++) {
                  if (isPaidAndBelongToCurrentCampaign(donations[i], start_date, end_date)) {
                    currentUserCurrentCampaignSum = currentUserCurrentCampaignSum + donations[i].amount
                  }
                }
              }
              currentCampaignSum.current += currentUserCurrentCampaignSum;
            })
            // eslint-disable-next-line no-loop-func
            .finally(() => {
              newClassesRankDataClean.current = []
              for (let i = 0; i < possibleClasses.length; i += 1) {
                newClassesRankDataClean.current.push({ name: possibleClasses[i], "Valor Arrecadado": classesRankData.SearchFor[possibleClasses[i]] })
              }
            })
        }
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading("campaign-data-fetch", false);
        setAllTimeAmount(currentSum.current);
        if (currentSum.current !== 0) {
          setStartDate("random string")
        }
        setClassesRankDataClean([...newClassesRankDataClean.current])
        setCurrentCampaignAmount(currentCampaignSum.current);
        setProgress(Math.round(100 * currentCampaignSum.current / goalAmount))
      });
  }, [start_date]);

  useEffect(() => {
    if (user?.isMonthlySubscribed && currentStep === "amount") {
      setDonation({
        ...donation,
        amount: user.monthlySubscription?.amount,
        message: user.monthlySubscription?.message,
        whoToThanks: user.monthlySubscription?.whoToThanks,
        paymentDay: user.monthlySubscription?.paymentDay,
        userId: user.id,
        isInMemoriam: user.monthlySubscription?.isInMemoriam,
        guild: user.monthlySubscription?.guild,
      });
    } else {
      setDonation({
        ...donation,
        userId: user?.id,
      });
    }
  }, [user]);

  return <>
    <WarningDialog
      onClose={async () => {
        if (errorToDisplay === "auth-fail") {
          await firebase.auth().signOut();
          window.location.reload();
        } else {
          if (errorToDisplay === "invalid-payment-method") {
            setCurrentStep("payment");
          }
          if (successfulDonation) {
            if (donation.userId === "anonymous") {
              window.location.replace("/doe");
            } else {
              window.location.replace("/area-do-doador");
            }
          }
          if (successfulPasswordReset) {
            window.location.replace("/area-do-doador");
          }
          setErrorDetails([]);
          setErrorToDisplay(null);
        }
      }}
      isOpen={errorToDisplay !== null || successfulDonation}
      message={successfulDonation
        ? donation.type === "SINGLE"
          ? "Doação realizada com sucesso, muito obrigado!"
          : "Doação mensal configurada com sucesso, muito obrigado!"
        : successfulPasswordReset
          ? "Senha alterada com sucesso"
          : errorDetails.length
            ? `${getErrorMessagePtBr(errorToDisplay)} Detalhes:\n - ${errorDetails.join("\n - ")}`
            : getErrorMessagePtBr(errorToDisplay)
      }
      isError={!successfulDonation || errorToDisplay !== null}
    />

    <ConfirmationDialog
      actionToConfirm={actionToConfirm}
      setActionToConfim={setActionToConfirm}
    />
    <LoginResetDialog
      isOpen={isLoginResetDialogOpen}
      setIsOpen={setIsLoginResetDialogOpen}
      firebase={firebase}
      loading={loading}
      setLoading={setLoading}
      errorHandler={(code) => {
        switch (code) {
          case "auth/invalid-email":
            setErrorToDisplay("invalid-email");
            return;
          case "auth/user-not-found":
            setErrorToDisplay("no-user-to-email");
            return;
          default:
            setErrorToDisplay("internal-error");
            return;
        }
      }}
    />
    <PrivacyPolicyDialog
      isPrivacyPolicyOpened={isPrivacyPolicyDialogOpen}
      setIsPrivacyPolicyOpened={setIsLoginResetDialogOpen}
    />

    <header>
      <div className="l-main-logo-container">
        <a href="https://doemedicinausp.com.br" target="_blank">
          <img
            className="l-main-logo-img"
            src={logoUrl}
            alt="logo endowment FMUSP"
          />
        </a>
      </div>
    </header>

    <div className="l-main-container">
      <TabBar
        className="l-tab-bar"
        activeIndex={activeIndex}
        handleActiveIndexUpdate={activeIndexHandler}
      >
        <Tab>
          <span className='mdc-tab__text-label'>Doe</span>
        </Tab>
        <Tab>
          <span className='mdc-tab__text-label'>Área do doador</span>
        </Tab>
        <Tab>
          <span className='mdc-tab__text-label'>Progresso</span>
        </Tab>
      </TabBar>
      {
        remainingDays >= 0 ?
          <div className="l-header">
            <HorizontalBarChart campaignTitle={campaignTitle} progress={progress} currentAmount={currentCampaignAmount} goalAmount={goalAmount} remainingDays={remainingDays} />
          </div>
          : <></>
      }
      <div className="l-content">
        <TabPanel value={activeIndex} index={0}>
          <NewDonation
            firebase={firebase}
            donation={donation}
            loading={loading}
            setDonation={setDonation}
            firebaseUser={firebaseUser}
            setLoading={setLoading}
            user={user}
            setUser={setUser}
            setCurrentStep={setCurrentStep}
            currentStep={currentStep}
            setIsLoginResetDialogOpen={setIsLoginResetDialogOpen}
            setIsPrivacyPolicyDialogOpen={setIsPrivacyPolicyDialogOpen}
            setErrorToDisplay={setErrorToDisplay}
            setSuccessfulDonation={setSuccessfulDonation}
            setErrorDetails={setErrorDetails}
          />
        </TabPanel>
        <TabPanel value={activeIndex} index={1}>
          <Manage
            firebase={firebase}
            isPasswordConfig={isPasswordConfig}
            resetPasswordCode={resetPasswordCode}
            loading={loading}
            setLoading={setLoading}
            user={user}
            setUser={setUser}
            actionToConfirm={actionToConfirm}
            setActionToConfirm={setActionToConfirm}
            errorToDisplay={errorToDisplay}
            setErrorToDisplay={setErrorToDisplay}
            activeIndexHandler={activeIndexHandler}
            donation={donation}
            setDonation={setDonation}
            setIsLoginResetDialogOpen={setIsLoginResetDialogOpen}
            setSuccessfulPasswordReset={setSuccessfulPasswordReset}
          />      </TabPanel>
        <TabPanel value={activeIndex} index={2}>
          <ClassesRankingBarChart {...{ classesRankDataClean }} />
          <AllTimeAmount allTimeAmount={allTimeAmount} />
        </TabPanel>
      </div>

      {currentStep === "amount" && activeIndex === 0 &&
        <div className="l-transfer-box">
          <p>Para doar via transferência bancária, utilize os dados abaixo:</p>
          <p><strong>ASSOCIAÇÃO FUNDO DE APOIO À FACULDADE DE MEDICINA DA UNIVERSIDADE DE SÃO PAULO</strong></p>
          <p>CNPJ: 29.224.593/0001-51</p>
          <p>Banco Santander (033)</p>
          <p>Agência: 0201</p>
          <p>Conta corrente: 130964673</p>
          <p>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdihlhD6wkM_B_m_u0hiG9eeAxZdyawBYqo_OgyBNsW5VqBzg/viewform"
              target="_blank"
            >
              Após a doação clique aqui
            </a>
          </p>
        </div>
      }

      <footer className="l-main-footer">
        <div className="l-main-footer-grid-container">
          <img className="l-footer-logo-img" src={logoUrl} />
          <div className="l-footer-info-area">
            <p><a
              href="https://docs.google.com/forms/d/e/1FAIpQLSevr8zvO4JxJyf2wZ9CCLYFrHt76hmMtsnwol1j4t-3Zo-WQQ/viewform"
              target="_blank"
            >Reportar problemas e feedback</a> | <a target="_blank" href="https://doemedicinausp.com.br">Site institucional</a></p>
            <p>Av. Dr. Arnaldo, 455 - Cerqueira César, São Paulo (SP) </p>
            <p>Sala 1107A | CEP: 01246-903</p>
            <p>
              Contato: endowment@fm.usp.br ou (11) 91061-2239
            </p>
          </div>
        </div>
      </footer>
    </div>
  </>
}

export default DonationPortal;
