import React, {FunctionComponent, useEffect, useState} from "react";
import { app } from "firebase";
import LoadingSetter from "../../LoadingSetter";
import {MonthlyReport} from "../../../entities/Reports";
import { Button } from "@material/react-button";
import MaterialIcon from "@material/react-material-icon";
import {monthStrings} from "../../monthNames";
import { toBrlValue } from "helpers";

export interface DashboardProps {
  firebase: app.App;
  loading: string[];
  setLoading: LoadingSetter;
}

const now = new Date();
const yearAgo = new Date();
yearAgo.setFullYear(now.getFullYear() - 1);

const currentYear = now.getFullYear();
const availableYears = currentYear > 2018
  ? [...(Array(currentYear - 2018).keys() as unknown as number[])].map(i => 2019 + i)
  : [];


const getMonthlyReportId = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  return `monthly-${year}-${month >= 10 ? month : "0" + month}`;
}

const getGeneralCsvData = (reports: MonthlyReport[]): string => {
  const header = [
    "Ano e mês",
    "Número total de doações",
    "Ticket médio de todas as doações",
    "Total doado",
    "Total arrecadado",
    "Doações mensais - número de assinaturas",
    "Doações mensais - ticket médio",
    "Doações mensais - total doável",
    "Doações mensais - total arrecadado",
    "Doações mensais - número de doações bem sucedidas",
    "Doações únicas - número de doações",
    "Doações únicas - ticket médio",
    "Doações únicas - total doado",
    "Doações únicas - total arrecadado"
  ].join(",") + "\n";

  const data = reports
    .map(report => [
      `"${monthStrings[report.month - 1]} de ${report.year}"`,
      `${report.numberOfDonations}`,
      `"${toBrlValue(report.numberOfDonations === 0
        ? 0
        : (report.totalDonatedAmount/report.numberOfDonations)
      )}"`,
      `"${toBrlValue(report.totalDonatedAmount)}"`,
      `"${toBrlValue(report.totalCollectedAmount)}"`,
      `${report.partials.subscriptions.numberOfSubscriptions}`,
      `"${toBrlValue(report.partials.subscriptions.numberOfSuccessfulDonations === 0
        ? 0
        : (report.partials.subscriptions.collectedAmount/report.partials.subscriptions.numberOfSuccessfulDonations))}"`,
      `"${toBrlValue(report.partials.subscriptions.targetRawAmount)}"`,
      `"${toBrlValue(report.partials.subscriptions.collectedAmount)}"`,
      `${report.partials.subscriptions.numberOfSuccessfulDonations}`,
      `${report.partials.single.numberOfDonations}`,
      `"${toBrlValue(report.partials.single.numberOfDonations === 0
        ? 0
        : (report.partials.single.collectedAmount/report.partials.single.numberOfDonations))}"`,
      `"${toBrlValue(report.partials.single.rawAmount)}"`,
      `"${toBrlValue(report.partials.single.collectedAmount)}"`,
    ].join(","))
    .join("\n");

  return header + data;
}


const getGuildsCsvData = (reports: MonthlyReport[]): string => {
  const header = [
    "Ano e mês",
    "Agremiação",
    "Número total de doações",
    "Ticket médio de todas as doações",
    "Total doado",
    "Total arrecadado",
  ].join(",") + "\n";

  const data = reports
    .flatMap(report => [
      {
        name: "Não associado a agremiação",
        numberOfDonations: report.partials.withoutGuild.numberOfDonations,
        rawAmount: report.partials.withoutGuild.rawAmount,
        collectedAmount: report.partials.withoutGuild.collectedAmount,
      }, ...report.guilds].map(guild => [
      `${monthStrings[report.month - 1]} de ${report.year}`,
      `"${guild.name}"`,
      `${guild.numberOfDonations}`,
      `"${toBrlValue(guild.numberOfDonations === 0
        ? 0
        : (guild.rawAmount/guild.numberOfDonations))}"`,
      `"${toBrlValue(guild.rawAmount)}"`,
      `"${toBrlValue(guild.collectedAmount)}"`,
    ].join(",")))
    .join("\n");

  return header + data;
}


const getDownloadFileName = (): string => {
  const now = new Date();
  const timestamp = `${now.toISOString().replace(/:/g, "_").replace(".", "__")}`;
  return `relatorio-endowment-fmusp-${timestamp}.csv`;
}


const Dashboard: FunctionComponent<DashboardProps> = (props) => {
  const {firebase, loading, setLoading} = props;
  const [monthlyReports, setMonthlyReports] = useState<MonthlyReport[]>([]);
  const [startDate, setStartDate] = useState<Date>(yearAgo);
  const [endDate, setEndDate] = useState<Date>(now);

  useEffect(() => {
    setLoading("reports-fetch");
    const collection = firebase.firestore().collection("monthly-reports");
    collection
      .where("type", "==", "monthly")
      .limit(3)
      .orderBy("id", "desc")
      .get()
      .then(snapshot => {
        const reports = snapshot.docs.map(doc => doc.data());
        setMonthlyReports(reports as unknown as MonthlyReport[]);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setLoading("reports-fetch", false);
      });
  }, []);

  const changeStartMonth = (month: number) => {
    setStartDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setMonth(month);
      return newDate;
    });
  }

  const changeEndMonth = (month: number) => {
    setEndDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setMonth(month);
      return newDate;
    });
  }

  const changeStartYear = (year: number) => {
    setStartDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setFullYear(year);
      return newDate;
    });
  }

  const changeEndYear = (year: number) => {
    setEndDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setFullYear(year);
      return newDate;
    });
  }

  const reportsWithGuild = monthlyReports.filter(r => r.guilds);

  return <>
    <div className="l-report-range-container">
      Período:
      <ul>
        <li>
          Início:
          <select
            name="Mês"
            id="start-month-select"
            value={startDate.getMonth()}
          >
            {monthStrings.map((monthName, indx) => <option
              value={indx}
              onClick={() => changeStartMonth(indx)}
            >
              {monthName}
            </option>)}
          </select>
          <select
            name="Ano" 
            id="start-year-select"
            value={startDate.getFullYear()}
          >
            {availableYears.map((year, indx) => <option
              value={indx + 2019}
              onClick={() => changeStartYear(indx + 2019)}
            >
              {year}
            </option>)}
          </select>
        </li>
        <li>
          Fim:
          <select name="Mês" id="end-month-select" value={endDate.getMonth()}>
            {monthStrings.map((monthName, indx) => <option
              value={indx}
              onClick={() => changeEndMonth(indx)}  
            >
              {monthName}
            </option>)}
          </select>
          <select name="Ano" id="end-year-select" value={endDate.getFullYear()}>
            {availableYears.map((year, indx) => <option
              value={indx + 2019}
              onClick={() => changeEndYear(indx + 2019)} 
            >
              {year}
            </option>)}
          </select>
        </li>
      </ul>
    </div>

    <Button
      className="l-report-query-button"
      outlined={true}
      disabled={loading.includes("reports-fetch")}
      onClick={async () => {
        setLoading("reports-fetch");
        try {
          const collection = firebase.firestore().collection("monthly-reports");
          
          const snapshot = await collection
            .where("type", "==", "monthly")
            .where("id", ">=", getMonthlyReportId(startDate))
            .where("id", "<=", getMonthlyReportId(endDate))
            .limit(100)
            .orderBy("id", "desc")
            .get();        
          const reports = snapshot.docs.map(doc => doc.data()); 

          setMonthlyReports(reports as unknown as MonthlyReport[]);
        } catch(err) {
          console.error(err);
        } finally {
          setLoading("reports-fetch", false);
        }
      }}
    >Consultar</Button>
    
    {monthlyReports && <>
      <h3>Resumo:</h3>
      <table>
        <thead>
          <tr>
            <th>Ano e Mês</th>
            <th>Número de doações</th>
            <th>Ticket Médio</th>
            <th>Total doado</th>
            <th>Total arrecadado</th>
          </tr>
        </thead>
        <tbody>
          {monthlyReports.map(report => <tr>
            <td>{monthStrings[report.month - 1]} de {report.year}</td>
            <td>{report.numberOfDonations}</td>
            <td>{toBrlValue(report.numberOfDonations === 0
              ? 0
              : (report.totalDonatedAmount/report.numberOfDonations))}</td>
            <td>{toBrlValue(report.totalDonatedAmount)}</td>
            <td>{toBrlValue(report.totalCollectedAmount)}</td>
          </tr>)
          }
        </tbody>
      </table>

      <h3>Detalhes: Doações mensais</h3>
      <table>
        <thead>
          <th>Ano e Mês</th>
          <th>Número de assinaturas</th>
          <th>Ticket médio</th>
          <th>Total doável</th>
          <th>Total arrecadado</th>
          <th>Número de doações bem sucedidas</th>
        </thead>
        <tbody>
          {monthlyReports.map(report => <tr>
            <td>{monthStrings[report.month - 1]} de {report.year}</td>
            <td>{report.partials.subscriptions.numberOfSubscriptions}</td>
            <td>{toBrlValue(report.partials.subscriptions.numberOfSuccessfulDonations === 0
              ? 0
              : (report.partials.subscriptions.collectedAmount/report.partials.subscriptions.numberOfSuccessfulDonations))}
            </td>
            <td>{toBrlValue(report.partials.subscriptions.targetRawAmount)}</td>
            <td>{toBrlValue(report.partials.subscriptions.collectedAmount)}</td>
            <td>{report.partials.subscriptions.numberOfSuccessfulDonations}</td>
          </tr>)
          }
        </tbody>
      </table>

      <h3>Detalhes: Doações únicas</h3>
      <table>
        <thead>
          <th>Ano e Mês</th>
          <th>Número de doações</th>
          <th>Ticket Médio</th>
          <th>Total doado</th>
          <th>Total arrecadado</th>
        </thead>
        <tbody>
          {monthlyReports.map(report => <tr>
            <td>{monthStrings[report.month - 1]} de {report.year}</td>
            <td>{report.partials.single.numberOfDonations}</td>
            <td>{toBrlValue(report.partials.single.numberOfDonations === 0
              ? 0
              : (report.partials.single.collectedAmount/report.partials.single.numberOfDonations))}
            </td>
            <td>{toBrlValue(report.partials.single.rawAmount)}</td>
            <td>{toBrlValue(report.partials.single.collectedAmount)}</td>
          </tr>)
          }
        </tbody>
      </table>
      
      <h3>Detalhes: Agremiações</h3>

      <table>
        <thead>
          <th>Ano e Mês</th>
          <th>Agremiação</th>
          <th>Número de doações</th>
          <th>Ticket Médio</th>
          <th>Total doado</th>
          <th>Total arrecadado</th>
        </thead>
        <tbody>
          {reportsWithGuild.flatMap(report => [{
            name: "Não associado a agremiação",
            numberOfDonations: report.partials.withoutGuild.numberOfDonations,
            rawAmount: report.partials.withoutGuild.rawAmount,
            collectedAmount: report.partials.withoutGuild.collectedAmount,
          }, ...report.guilds].map(guild =>
            <tr key={`${report.month}-${report.year}-${guild}`}>
              <td>{monthStrings[report.month - 1]} de {report.year}</td>
              <td>{guild.name}</td>
              <td>{guild.numberOfDonations}</td>
              <td>{toBrlValue(guild.numberOfDonations === 0
                ? 0
                : (guild.rawAmount/guild.numberOfDonations))}
              </td>
              <td>{toBrlValue(guild.rawAmount)}</td>
              <td>{toBrlValue(guild.collectedAmount)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Button
        className="m-csv-download-button"
        trailingIcon={<MaterialIcon icon="get_app" />}
        outlined={true}
        onClick={() => {
          const csv = getGeneralCsvData(monthlyReports);
          const hiddenElement = document.createElement("a");
          hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
          hiddenElement.target = "_blank";
          hiddenElement.download = getDownloadFileName();
          hiddenElement.click();
        }}
      >CSV Geral</Button>
      
      <Button
        className="m-csv-download-button"
        trailingIcon={<MaterialIcon icon="get_app" />}
        outlined={true}
        onClick={() => {
          const csv = getGuildsCsvData(reportsWithGuild);
          const hiddenElement = document.createElement("a");
          hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
          hiddenElement.target = "_blank";
          hiddenElement.download = getDownloadFileName();
          hiddenElement.click();
        }}
      >CSV Agremiações</Button>
    </>}
  </>
}


export default Dashboard;
