import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import BarChartBottom from './BarChartBotton';
import BarChartTop from './BarChartTop';
import { Typography } from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        background: `linear-gradient(90deg, #35b0b8 ${50}%, #b3e1e4 100%)`,
    },

}));

const CampaignTitle = styled(Typography)`
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    color: #b3e1e4;
    margin-bottom: 10px;
`

export default function HorizontalBarChart(props: any) {
    return (
        <Box sx={{ height: '90px', width: '100%' }}>
            <CampaignTitle>{props.campaignTitle}</CampaignTitle>
            <BarChartTop currentAmount={props.currentAmount} progress={props.progress}></BarChartTop>
            <Box sx={{ flexGrow: 0.6, display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', p: 0 }}>
                    <BorderLinearProgress variant="determinate" value={Math.min(100, props.progress)} />
                </Box>
            </Box>
            <BarChartBottom goalAmount={props.goalAmount} remainingDays={props.remainingDays}></BarChartBottom>
        </Box>

    );
}