import React, { FunctionComponent, useEffect, useState } from "react";
import { app } from "firebase";
import LoadingSetter from "../../LoadingSetter";
import { Campaign } from "../../../entities/Campaign";
import CampaignCard from "../../components/CampaignCard";
import MaterialIcon from "@material/react-material-icon";
import TextField, { HelperText, Input } from "@material/react-text-field";
import { Button } from "@material/react-button";
import { Box } from "@mui/material";
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export interface SetCampaignProps {
    firebase: app.App;
    loading: string[];
    setLoading: LoadingSetter;
}

const DoubleBox = styled(Box)`
    display: flex;
    margin-bottom: 5px
    justify-content: space-between;
`

const SetCampaign: FunctionComponent<SetCampaignProps> = (props) => {
    const { firebase, loading, setLoading } = props;
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const campaignCollection = firebase.firestore().collection("campaigns");
    const [campaign, setCampaign] = useState<Partial<Campaign>>({});

    useEffect(() => {
        setLoading("campaigns-fetch");
        campaignCollection.get().then(snapshot => {
            const received = snapshot.docs.map(doc => doc.data());
            setCampaigns(received as unknown as Campaign[]);
        }).catch(err => {
            console.error(err);
            alert("Não foi possível obter as campanhas do banco de dados");
        }).finally(() => {
            setLoading("campaigns-fetch", false);
        })
    }, []);

    return <>
        <h3>Definir Campanha </h3>
        <h4>A última campanha criada é considerada a Campanha Atual</h4>

        <div className="l-campaigns">

            <TextField
                helperText={<HelperText>Pense num nome que cative</HelperText>}
                label="Nome da Campanha"
            >
                <Input
                    value={campaign?.name}
                    onChange={(e: any) => {
                        setCampaign({ ...campaign, name: e.currentTarget.value })
                    }}
                />
            </TextField>
            <DoubleBox>
                <TextField
                    helperText={<HelperText>Doações serão contabilizadas a partir dessa data</HelperText>}
                    label="Data de Início"
                >
                    <Input
                        type='date'
                        value={campaign?.begin_date}
                        onChange={(e: any) => {
                            setCampaign({ ...campaign, begin_date: e.currentTarget.value })
                        }}
                    />
                </TextField>

                <TextField
                    helperText={<HelperText>Doações serão contabilizadas até essa data</HelperText>}
                    label="Data do Fim"
                >
                    <Input
                        type='date'
                        value={campaign?.end_date}
                        onChange={(e: any) => {
                            setCampaign({ ...campaign, end_date: e.currentTarget.value })
                        }}
                    />
                </TextField>

                <FormControlLabel
                    value={campaign?.is_monthly_goal}
                    control={<Checkbox checked={campaign?.is_monthly_goal}
                        onChange={(e: any) => {
                            setCampaign({ ...campaign, is_monthly_goal: e.target.checked })
                        }}
                    />
                    }
                    label="Foco em Arrecadação Recorrente"
                    labelPlacement="start"
                />
            </DoubleBox>


            <TextField
                helperText={<HelperText>Valor alvo da arrecadação, sem levar em conta taxas</HelperText>}
                label="Arrecadação Bruta Alvo (em reais)"
            >
                <Input
                    type='number'
                    value={campaign?.goal_amount}
                    onChange={(e: any) => {
                        setCampaign({ ...campaign, goal_amount: e.currentTarget.value })
                    }}
                />
            </TextField>
            <Button
                trailingIcon={<MaterialIcon icon="campaign" />}
                className="l-campaign-button"
                outlined
                disabled={!!loading.length}
                onClick={async () => {
                    const { name, begin_date, end_date, goal_amount, is_monthly_goal } = campaign;
                    if (!name) {
                        alert("Por favor, digite o nome da campanha");
                        return;
                    }
                    if (!begin_date) {
                        alert("Por favor, digite a data de início da campanha");
                        return;
                    }
                    if (!end_date) {
                        alert("Por favor, digite a data do fim da campanha");
                        return;
                    }
                    if (!goal_amount) {
                        alert("Por favor, digite o valor alvo da campanha");
                        return;
                    }
                    if (end_date <= begin_date) {
                        alert("A data final deve vir depois da data de início");
                        return;
                    }
                    setLoading("new-campaign");
                    try {
                        const firestore = firebase.firestore();
                        const collection = firestore.collection("campaigns");
                        await collection.add({
                            name: name.trim(),
                            begin_date,
                            end_date,
                            goal_amount,
                            is_monthly_goal,
                            timestamp: (new Date()).toISOString(),
                        } as Campaign);

                        alert("Campanha criada");
                        setCampaign({});
                    } catch (err) {
                        console.error(err);
                        alert("Não foi possível criar a campanha");
                    } finally {
                        setLoading("new-campaign", false);
                    }
                }}
            >
                Criar
            </Button>
        </div>

        <h3>Campanhas Passadas</h3>

        {campaigns.sort((n1: any, n2: any) => {
            if (n1.timestamp > n2.timestamp) {
                return -1;
            }

            if (n1.timestamp < n2.timestamp) {
                return 1;
            }

            return 0;
        }).map(campaign => <CampaignCard
            firebase={firebase}
            loading={loading}
            setLoading={setLoading}
            key={campaign.id}
            campaign={campaign}
            className="m-admin-update-card-section"
        />)}
    </>
}


export default SetCampaign;
