import React, {FunctionComponent, useState} from "react";
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from '@material/react-dialog';


export type ActionToCofirmCallback = (() => void) | (() => Promise<void>); 

export type ActionToCofirm = null | {
  confirmText?: string;
  cancelText?: string;
  title: string;
  callback: ActionToCofirmCallback;
};

export interface ConfirmationDialogProps {
  actionToConfirm: ActionToCofirm;
  setActionToConfim: (action: ActionToCofirm) => void;
}

const ConfirmationDialog: FunctionComponent<ConfirmationDialogProps> = (props) => {
  const {actionToConfirm, setActionToConfim} = props;

  if (actionToConfirm !== null) {
    const {title, confirmText, cancelText, callback} = actionToConfirm;
    return <>
      <Dialog
        onClose={async (action) => {
          if (action === "confirm") {
            callback();
          }
          setActionToConfim(null);
        }}
        open={actionToConfirm !== null}>
        <DialogTitle>Atenção</DialogTitle>
        <DialogContent>
          <p>{title}</p>
        </DialogContent>
        <DialogFooter>
          <DialogButton isDefault={false} action="confirm">{confirmText ? confirmText : "Confirmar"}</DialogButton>
          <DialogButton isDefault={true} outlined={true} action="dismiss">{cancelText ? cancelText : "Cancelar"}</DialogButton>
        </DialogFooter>
      </Dialog>
    </>
  } else {
    return null;
  }
}

export default ConfirmationDialog;
