import React, {FunctionComponent, useState} from "react";
import { app } from "firebase";
import "../Forms.scss";
import { Button } from "@material/react-button";
import Donation from "../../../../entities/Donation";
import TextField, {Input} from '@material/react-text-field';
import Radio, {NativeRadioControl} from '@material/react-radio';
import User from "../../../../entities/User";
import MaterialIcon from "@material/react-material-icon";
import config from "../../../config";

const { minAmount } = config;

interface AmountProps {
  user: User | null;
  firebase: app.App;
  donation: Partial<Donation>;
  setDonation: (donation: Partial<Donation>) => void;
}

const Amount: FunctionComponent<AmountProps> = (props) => {
  const {donation, firebase, setDonation, user} = props;
  const selectedAmount = donation.amount;
  const amountPresets = [100, 200, 300];

  const handleCustomAmount = (e: any) => {
    if (typeof e?.currentTarget?.value !== "string") {
      throw(new Error("Invalid event target value"));
    }
    const value = e.currentTarget.value
      .replace(/\s/g, "")
      .replace("R$", "")
      .replace(",", ".");
    
    let amount: number;
    if(value === "") {
      amount = 0;
    } else {
      amount = (new Number(value)).valueOf();
      if(isNaN(amount)) {
        amount = selectedAmount ? selectedAmount : 0;
      }
    }

    setDonation({...donation, amount});
  }

  return <>
    <div className="l-preset-amount-buttons-container">
      {amountPresets.map((amount: number) => (
        <Button
          outlined={true}
          raised={selectedAmount === amount}
          className={
            selectedAmount === amount
              ? "s-selected-button"
              : ""
          }
          onClick={() => {
            setDonation({...donation, amount: amount});
          }}
        >R${amount}</Button>
      ))}
    </div>
    <h3>Deseja doar outro valor?</h3>
    <TextField
      className="l-custom-amount-field"
      label={
        (selectedAmount as number) >= minAmount
          ? user?.isMonthlySubscribed && user?.monthlySubscription?.amount === selectedAmount
            ? "Valor mensal atual"
            : amountPresets.includes(selectedAmount as number)
              ? "Valor desejado"
              : "Doarei outro valor"
          : `O valor mínimo é R$${minAmount}`
      }
      onTrailingIconSelect={() => {
        if (typeof user?.monthlySubscription?.amount === "number") {
          setDonation({...donation, amount: user.monthlySubscription?.amount});
        }
      }}
      trailingIcon={
        user?.isMonthlySubscribed && user?.monthlySubscription?.amount !== selectedAmount && donation.type === "MONTHLY"
          ? <MaterialIcon
              icon="cancel"
              role="button"
            />
          : undefined
      }
    >
    <Input
      isValid={(selectedAmount as number) >= minAmount}
      value={isNaN(selectedAmount ? selectedAmount : NaN)
        ? "R$ "
        : "R$ " + selectedAmount
      }
      onChange={handleCustomAmount}
    />
    </TextField>
    <div className="l-amount-radio-container">
      <div className="l-amount-radio-grid">
        <div className="m-dontaion-radio">
          <Radio
            label={user?.isMonthlySubscribed
              ? "Alterar doação mensal"
              : "Doação mensal"
            }
            key="monthly"
          >
            <NativeRadioControl
              name="monthly"
              value="MONTHLY"
              id="monthly-subscription-radio"
              checked={donation.type === "MONTHLY"}
              onChange={(e: any) => setDonation({...donation, type: e.target.value})}
            />
          </Radio>
        </div>
        <div className="m-dontaion-radio">
          <Radio
            label={user?.isMonthlySubscribed ? "Doação única adicional" : "Doação única"}
            key="single"
          >
            <NativeRadioControl
              name="single"
              value="SINGLE"
              id="single-donation-radio"
              checked={donation.type === "SINGLE"}
              onChange={(e: any) => setDonation({...donation, type: e.target.value})}
            />
          </Radio>
        </div>
      </div>
    </div>
  </>
}

export default Amount;
