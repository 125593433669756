import React, {FunctionComponent} from "react";
import TextField, {Input, HelperText} from '@material/react-text-field';
import paymentGateway, {NewCard} from "../../../services/paymentGateway";
import Address from "../../../../entities/Address";
import Checkbox from "@material/react-checkbox";
import "../Forms.scss";


interface NewCreditCardProps {
  newCard: Partial<NewCard>;
  setNewCard: (newCard: Partial<NewCard>) => void;
  isTosaveAddress: boolean;
  setIsTosaveAddress: (isToSave: boolean) => void;
}

const NewCreditCard: FunctionComponent<NewCreditCardProps> = (props) => {
  const {newCard, setNewCard, isTosaveAddress, setIsTosaveAddress} = props;
  const {billingAddress} = newCard;

  const setAddressField = (updates: Partial<Address>) => {
    setNewCard({
      ...newCard,
      billingAddress: {
        ...newCard.billingAddress,
        ...updates,
      }
    })
  }

  return <>
    <TextField
      label="Número do cartão"
      helperText={<HelperText>Conforme escrito no cartão</HelperText>}
    >
      <Input
        isValid={newCard.number !== ""}
        value={newCard.number}
        onChange={(e: any) => setNewCard({
          ...newCard,
          number: e.currentTarget.value,
        })}
      />
    </TextField>

    <TextField
      label="Nome do titular do cartão"
      helperText={<HelperText>Conforme escrito no cartão</HelperText>}
    >
      <Input
        isValid={newCard.name !== ""}
        value={newCard.name}
        onChange={(e: any) => setNewCard({...newCard, name: e.currentTarget.value})}
      />
    </TextField>

    <TextField
      label="CPF ou CNPJ do titular do cartão"
      helperText={<HelperText>CPF ou CNPJ</HelperText>}
    >
      <Input
        isValid={newCard.document !== ""}
        value={newCard.document}
        onChange={(e: any) => setNewCard({...newCard, document: e.currentTarget.value})}
      />
    </TextField>

    <div className="l-valid-date-box">
      <span>Validade:</span>
      <TextField
        label="Mês"
        className="m-valid-date-input"
      >
        <Input
          isValid={!newCard.month || paymentGateway.validateCardMonth(newCard.month)}
          value={newCard.month}
          onChange={(e: any) => {
            const value = e.currentTarget.value;
            if (value.length <= 2) {
              setNewCard({...newCard, month: e.currentTarget.value});
            }
          }}
        />
      </TextField>

      <TextField
        label="Ano"
        className="m-valid-date-input"
      >
        <Input
          isValid={!newCard.year || paymentGateway.validateCardYear(newCard.year)}
          value={newCard.year}
          onChange={(e: any) => {
            const value = e.currentTarget.value;
            if (value.length <= 2) {
              setNewCard({...newCard, year: e.currentTarget.value});
            }
          }}
        />
      </TextField>
    </div>

    <TextField
      className="l-cvv-box"
      label="CVV"
      helperText={<HelperText>Código de segurança no verso do cartão</HelperText>}
    >
      <Input
        isValid={newCard.cvv !== ""}
        value={newCard.cvv}
        onChange={(e: any) => setNewCard({...newCard, cvv: e.currentTarget.value})}
      />
    </TextField>

    <h3>Endereço do titular:</h3>

    <div className="m-left-big-field">
      <TextField
        label="Rua"
        helperText={<HelperText>Nome da rua, avenida, etc</HelperText>}
      >
        <Input
          value={billingAddress?.street}
          onChange={(e: any) => setAddressField({street: e.currentTarget.value})}
        />
      </TextField>
    </div>

    <div className="m-right-small-field">
      <TextField
        label="Número"
        helperText={<HelperText>Digite "N/A" se não hover número</HelperText>}
      >
        <Input
          value={billingAddress?.number}
          onChange={(e: any) => setAddressField({number: e.currentTarget.value})}
        />
      </TextField>
    </div>

    <TextField
      label="Bairro"
      helperText={<HelperText>Nome do bairro (opcional)</HelperText>}
    >
      <Input
        value={billingAddress?.district}
        onChange={(e: any) => setAddressField({district: e.currentTarget.value})}
      />
    </TextField>

    <TextField
      label="Complemento"
      helperText={<HelperText>Opcional, ex: (apto. 14)</HelperText>}
    >
      <Input
        value={billingAddress?.complement}
        onChange={(e: any) => setAddressField({complement: e.currentTarget.value})}
      />
    </TextField>

    <TextField
      label="CEP"
      helperText={<HelperText>CEP ou código postal</HelperText>}
    >
      <Input
        value={billingAddress?.postalCode}
        onChange={(e: any) => {
          const postalCode = e.currentTarget.value.replace(/[^0-9]/g, "");
          setAddressField({postalCode});
        }}
      />
    </TextField>

    <div className="m-left-big-field">
      <TextField
        label="Cidade"
        helperText={<HelperText>Nome da cidade</HelperText>}
      >
        <Input
          value={billingAddress?.city}
          onChange={(e: any) => setAddressField({city: e.currentTarget.value})}
        />
      </TextField>
    </div>

    <div className="m-right-small-field">
      <TextField
        label="Estado"
        helperText={<HelperText>UF</HelperText>}
      >
        <Input
          value={billingAddress?.state}
          onChange={(e: any) => {
            const state = e.currentTarget.value.toUpperCase().substring(0, 2);
            setAddressField({state});
          }}
        />
      </TextField>
    </div>

    <div className="m-checkbox-container l-save-address-check-container">
      <Checkbox
        className="m-checkbox-element"
        nativeControlId="is-my-address-check"
        checked={isTosaveAddress}
        onChange={(e) => {
          setIsTosaveAddress(e.target.checked);
        }}
      />
      <div className="m-checkbox-element">
        Atualizar meu endereço com os dados acima.
      </div>
    </div>
  </>
}

export default NewCreditCard;
