import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { toBrlValue } from '../helpers';
import { Typography } from '@mui/material';

const BarChartTopBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    height: 20px;
    width: 100%;
    margin-bottom: 10px;
`
const TextBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const MainText = styled(Typography)`
    font-size: 20px;
    font-weight: 700;
    color: #35b0b8;
    margin-left: 5px;
    margin-right: 5px;
`
export default function BarChartTop(props: any) {
    return (

        <BarChartTopBox>
            <TextBox>
                <MainText>{toBrlValue(props.currentAmount)}</MainText>
            </TextBox>
            <TextBox>
                <MainText>{props.progress} %</MainText>
            </TextBox>
        </BarChartTopBox>

    )
}