import Donation from "../../../entities/Donation";
import User from "../../../entities/User";
import Address from "../../../entities/Address";
import {UserDataMainOption, NewUser} from "../../components/forms/NewDonationSteps";
import {app} from "firebase/app";
import {cpf, cnpj} from "cpf-cnpj-validator";
import {NewCard} from "../../services/paymentGateway";
import { CreditCard, Payment } from "../../../entities/Payment";
import { LoginData } from "../../components/forms/Login";
import Result from "../../Result";
import functions from "./../../services/functions";
import LoadingSetter from "../../LoadingSetter";
import paymentGateway from "../../services/paymentGateway";
import config from "../../config";


const { minAmount } = config;

export type StepName =
  | "amount"
  | "user-data"
  | "new-credit-card"
  | "payment"
  | "additional-info";

export interface PreactionDependencies {
  donation: Partial<Donation>;
  newUser: NewUser;
  selectedMethod: Payment;
  setSelectedMethod: (method: Payment) => void;
  userDataMainOption: UserDataMainOption;
  firebase: app.App;
  setLoading: LoadingSetter;
  newCard: Partial<NewCard>;
  user: User | null;
  setUser: (user: User | null) => void;
  loginData: LoginData;
  setAnonymousCreditCardHash: (hash: string | null) => void;
  acceptedPrivacyPolicy: boolean;
  isToSaveAddress: boolean;
}

export type Preaction = (dependencies: PreactionDependencies) => Result | Promise<Result>;


const amount: Preaction = (dependencies) => {
  const {donation} = dependencies;
  if (donation.amount && donation.type && donation.amount >= minAmount) {
    return {success: true, data: undefined};
  } else {
    return {success: false, error: "invalid-amount"};
  }
}

const userData: Preaction = async (dependencies) => {
  const {
    newUser,
    firebase,
    setLoading,
    userDataMainOption,
    loginData,
    acceptedPrivacyPolicy
  } = dependencies;
  const {isCompany, document, email, name , password, isAlumni, year} = newUser;
  if (userDataMainOption === "anonymous") {
    return {success: true, data: undefined};
  }
  if (userDataMainOption === "login") {
    if (!loginData.email.includes("@")) {
      return {success: false, error: "invalid-email"};;
    }
    if (loginData.password.length < 6) {
      return {success: false, error: "invalid-password"};
    }
    setLoading("login");
    try {
      await firebase.auth().signInWithEmailAndPassword(loginData.email, loginData.password);
      setLoading("login", false);
      return {success: true, data: undefined};
    } catch(err) {
      setLoading("login", false);
      switch (err.code) {
        case "auth/invalid-email":
          return {success: false, error: "invalid-email"};
        case "auth/user-not-found":
          return {success: false, error: "no-user-to-email"};
        case "auth/wrong-password":
          return {success: false, error: "wrong-password"};
        default:
          return {success: false, error: "login-fail"};
      }
    }
  }
  if (isCompany && !cnpj.isValid(document)) {
    return {success: false, error: "invalid-cnpj"};
  }
  if (!isCompany && !cpf.isValid(document)) {
    return {success: false, error: "invalid-cpf"};;
  }
  if (!email.includes("@")) {
    return {success: false, error: "invalid-email"};;
  }
  if (name?.length < 3) {
    return {success: false, error: "invalid-name"};;
  }
  if (password?.length < 6) {
    return {success: false, error: "invalid-password"};
  }
  if (!acceptedPrivacyPolicy) {
    return {success: false, error: "privacy-policy-not-accepted"}
  }


  setLoading("new-user-creation");
  try {
    const formatedDocument: string = isCompany ? cnpj.format(document) : cpf.format(document);
    if(await functions.conflictCheck(formatedDocument)) {
      setLoading("new-user-creation", false);
      return {success: false, error: "conflict"}
    };
    const auth = firebase.auth();
    const collection = firebase.firestore().collection("users");
    const displayName = newUser.nickname ? newUser.nickname : name.split(" ")[0];
    const credential = await auth.createUserWithEmailAndPassword(email, password);
    credential.user?.updateProfile({displayName});
    const createdUser = credential.user as firebase.User;
    delete (newUser as any).password;
    const userData: User = {
      ...newUser,
      document: formatedDocument,
      id: createdUser.uid,
      creditCards: [],
      defaultCreditCardIndex: -1,
      registerDate: (new Date()).toISOString(),
      isMonthlySubscribed: false, // Just at this moment
    }
    await collection.doc(createdUser.uid).set(userData);

    setLoading("new-user-creation", false);
    return {success: true, data: undefined};
  } catch(err) {
    setLoading("new-user-creation", false);
    switch(err.code) {
      case "auth/email-already-in-use":
        return {success: false, error: "existing-user"};
      case "auth/invalid-email":
        return {success: false, error: "invalid-email"};
      case "auth/weak-password":
        return {success: false, error: "invalid-password"};
      default:
        console.error(err);
        return {success: false, error: "unable-to-crate-user"};
    }
  }
}

const payment: Preaction = (dependencies) => {
  return {success: true, data: undefined};
}

const newCreditCard: Preaction = async (dependencies) => {
  const {setLoading} = dependencies;
  const paymentGatewayHealth = paymentGateway.healthCheck();
  if (!paymentGatewayHealth.success) {
    return paymentGatewayHealth;
  }
  const validNewCardResult = paymentGateway.validateNewCard(dependencies.newCard);
  if (!validNewCardResult.success) {
    return validNewCardResult;
  }
  const validNewCard = validNewCardResult.data;

  setLoading("add-new-card");
  try {
    const { number, name, document, month, year, billingAddress } = validNewCard;
    const company = paymentGateway.getCardCompany(number);
    const hash = await paymentGateway.getCardHash(validNewCard);

    if (dependencies.userDataMainOption === "anonymous") {
      dependencies.setAnonymousCreditCardHash(hash);
    } else {
      const {user, setUser, firebase, setSelectedMethod, isToSaveAddress} = dependencies;
      const collection = firebase.firestore().collection("users");
      const junoId = await functions.addCreditCard(hash);
      const last4digits = paymentGateway.getCardLast4digits(number)
      const newCreditCardArray: CreditCard[] = [...(user?.creditCards as CreditCard[]), {
        junoId,
        company,
        last4digits,
        expirationMonth: month,
        expirationYear: year,
        holderName: name,
        holderDocument: document,
        billingAddress: billingAddress as Address, 
      }];
      const updates: Partial<User> = isToSaveAddress
        ? {
          creditCards: newCreditCardArray,
          defaultCreditCardIndex: newCreditCardArray.length - 1,
          address: billingAddress,
        } : {
          creditCards: newCreditCardArray,
          defaultCreditCardIndex: newCreditCardArray.length - 1,
        };
      await collection.doc(user?.id).update(updates);
      
      setUser({
        ...(user as User),
        ...updates,
      });
      setSelectedMethod({
        type: "CREDIT_CARD",
        cardIndx: typeof updates.defaultCreditCardIndex === "number"
          ? updates.defaultCreditCardIndex
          : null,
        last4digits,
        company,
      });
    }

    setLoading("add-new-card", false);
    return {success: true, data: undefined};
  } catch(err) {
    console.error(err);
    setLoading("add-new-card", false);
    return {success: false, error: "unable-to-add-card"}
  }
}


const additionalInfo: Preaction = (dependencies) => {
  return {success: true, data: undefined};;
}



const preactions = new Map<StepName, Preaction>([
  ["amount", amount],
  ["user-data", userData],
  ["new-credit-card", newCreditCard],
  ["payment", payment],
  ["additional-info", additionalInfo],
]);

export default preactions;
