import React, {FunctionComponent, useState} from "react";
import { app } from "firebase";
import LoadingSetter from "../../LoadingSetter";
import TextField, { Input } from "@material/react-text-field";
import { Button } from "@material/react-button";
import User from "../../../entities/User";
import {cpf, cnpj} from "cpf-cnpj-validator";
import UserDataUpdate from "../../components/forms/Updates/UserDataUpdate";
import CreditCards from "../../components/forms/Updates/CreditCards";
import DonationsQuery from "../../components/DonationsQuery";
import MaterialIcon from "@material/react-material-icon";
import Donation from "../../../entities/Donation";


export interface DonnorsProps {
  firebase: app.App;
  loading: string[];
 setLoading: LoadingSetter;
}

type SearchInput = "cpf" | "cnpj" | "email" | "invalid";


const getSearchInputType = (input: string): SearchInput => {
  console.log(input);
  if (input.match(/@/g)?.length === 1) {
    return "email";
  } else if (cpf.isValid(input)) {
    return "cpf";
  } else if (cnpj.isValid(input)) {
    return "cnpj";
  } else {
    return "invalid";
  }
}

const getUsers = async (firestore: firebase.firestore.Firestore): Promise<User[]> => {
  return (await firestore.collection("users").get()).docs.map(doc => doc.data() as User);
};

const getCsvData = async (users: User[], firestore: firebase.firestore.Firestore): Promise<string> => {
  const header = [
    "Id",
    "Nome",
    "CPF/CNPJ",
    "Email",
    "Telefone",
    "Endereço",
    "Agradecimento público em nome de",
    "Agradecimento in memoriam"
  ].join(", ") + "\n";

  const data = (await Promise.all(users.map(async user => {
    const {
      id,
      name,
      document,
      email,
      phone,
      address,
      monthlySubscription,
      isMonthlySubscribed
    } = user;
    let whoToThanks = "";
    let isInMemoriam = false;
    if (monthlySubscription && isMonthlySubscribed) {
      whoToThanks = monthlySubscription.whoToThanks || "";
      isInMemoriam = !!monthlySubscription.isInMemoriam;
    } else {
      const lastDonation = (await firestore
        .collection(`users/${id}/donations`)
        .orderBy("date", "desc")
        .limit(1)
        .get()).docs.map(doc => doc.data() as Donation)[0];
      
      if(lastDonation) {
        whoToThanks = lastDonation.whoToThanks || "";
        isInMemoriam = !!lastDonation.isInMemoriam;
      }
    }

    return [
    id,
    name,
    document,
    email,
    phone,
    address && `"${address.street ?? ""}; ${address.number ?? ""}; ${address.district ?? ""}; ${address.postalCode ?? ""}; ${address.complement ?? ""}; ${address.city ?? ""} - ${address.state ?? ""}"; ${address.contry ?? ""}`,
    whoToThanks,
    whoToThanks && isInMemoriam ? "Sim" : "Não",
    ].join(", ")
  }))).join("\n");

  return header + data;
}


const Donnors: FunctionComponent<DonnorsProps> = (props) => {
  const {firebase, loading, setLoading} = props;
  const [searchInput, setSearchInput] = useState<string>("");
  const [showingDonnor, setShowingDonnor] = useState<User | null>();

  const firestore = firebase.firestore();

  return <>
    <p>Buscar doador:</p>
    <TextField className="l-search-input" label="email, CPF ou CNPJ">
      <Input
        value={searchInput}
        onKeyUp={(e: any) => {
          if (e.keyCode === 13) {
            e.preventDefault();
            document.getElementById("search-button")?.click();
          }
        }}
        onChange={(e:any) => setSearchInput(e.currentTarget.value)}
      />
    </TextField>
    <Button
      id="search-button"
      outlined={true}
      disabled={loading.includes("user-fetch")}
      onClick={async () => {
        setLoading("user-fetch");
        try {
          const collection = firebase.firestore().collection("users");
          let querySnapshot;

          switch(getSearchInputType(searchInput)) {
            case "email":
              querySnapshot = await collection
                .where("email", "==", searchInput)
                .get();
              break;
            case "cnpj":
              querySnapshot = await collection
                .where("document", "==", cnpj.format(searchInput))
                .get();
              break;
            case "cpf":
              querySnapshot = await collection
                .where("document", "==", cpf.format(searchInput))
                .get();
              break;
            default:
              querySnapshot = null;
          }

          const user = querySnapshot?.docs[0]?.data();
          if (querySnapshot?.docs?.length as number > 1) {
            alert("Cadastro duplicado!");
          }

          if (user instanceof Object) {
            setShowingDonnor(user as unknown as User);
          } else {
            setShowingDonnor(null);
            alert("Nenhum usuário encontrado");
          }
        } catch(err) {
          console.log(err);
          alert("Error inesperado!");
        } finally {
          setLoading("user-fetch", false);
        }
      }}
    >Buscar</Button>

    {showingDonnor && <>

      <div className="m-admin-update-card-section">
        <h3>Doações feitas:</h3>

        <DonationsQuery
          firebase={firebase}
          setLoading={setLoading}
          user={showingDonnor}
          cancelDonationHandler={async () => {
              setLoading("subscription-delete");
              try {
                const updates: Partial<User> = {
                  isMonthlySubscribed: false,
                }
                await firebase
                  .firestore()
                  .collection("users")
                  .doc(showingDonnor.id)
                  .update(updates);

                setShowingDonnor({
                  ...showingDonnor,
                  ...updates,
                });
              } catch(err) {
                console.log(err);
                alert("Não foi possível cancelar as doações mensais");
              } finally {
                setLoading("subscription-delete", false);
              }
            }
          }
        />

      </div>

      <div className="m-admin-update-card-section">
        <UserDataUpdate
          user={showingDonnor}
          setUser={setShowingDonnor}
          firebase={firebase}
          adminActions={true}
          errorHandler={(error) => {
            alert(error);
          }}
          setLoading={setLoading}
        />
      </div>

      <div className="m-admin-update-card-section">
        <h3>Cartões de Crédito:</h3>
        <CreditCards
          firebase={firebase}
          setUser={setShowingDonnor}
          user={showingDonnor}
          errorHandler={(code) => alert(code)}
          setLoading={setLoading}
          setActionToConfirm={(action) => {
            if (confirm(action?.title)) { // eslint-disable-line
              action?.callback();
            }
          }}
        />
      </div>

      <div className="m-admin-update-card-section">
        <h3>Doação mensal:</h3>
        {showingDonnor?.isMonthlySubscribed === true
          ? <p>Possui doação mensal</p>
          : <p>Não possui doação mensal</p>
        }
      </div>
    </>}
    
    <p>Exportar doadores:</p>

    <Button
      trailingIcon={<MaterialIcon icon="get_app" />}
      outlined
      onClick={async () => {
        setLoading("users-export");
        try {
          const csv = await getCsvData(await getUsers(firestore), firestore);
          const hiddenElement = document.createElement("a");
          hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
          hiddenElement.target = "_blank";
          hiddenElement.download = "doadores.csv";
          hiddenElement.click();
        } catch(err) {
          console.error(err);
          alert("Não foi possível exportar os doadores");
        } finally {
          setLoading("users-export", false);
        }
      }}
    >CSV</Button>
  </>
}


export default Donnors;
