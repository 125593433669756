import React, {FormEvent, FunctionComponent} from "react";
import TextField, {Input, HelperText} from '@material/react-text-field';
import Checkbox from "@material/react-checkbox";
import { Radio, NativeRadioControl } from "@material/react-radio";
import Login, {LoginData} from "../Login";
import Switch from "@material/react-switch";
import Donation from "../../../../entities/Donation";

export type UserDataMainOption = "create-user" | "login" | "anonymous";

export interface NewUser {
  name: string;
  nickname: string;
  document: string;
  email: string;
  receiveNewsletter: boolean;
  isAlumni: boolean;
  year: string;
  isCompany: boolean;
  password: string;
}


interface UserDataProps {
  mainOption: UserDataMainOption;
  setMainOption: (option: UserDataMainOption) => void;
  newUser: NewUser;
  setNewUser: (newUser: NewUser) => void;
  accepted: boolean;
  setAccepted: (accepted: boolean) => void;
  loginData: LoginData;
  setLoginData: (LoginData: LoginData) => void;
  donation: Partial<Donation>;
  setDonation: (donation: Partial<Donation>) => void;
  setIsLoginResetDialogOpen: (isOpen: boolean) => void;
  setIsPrivacyPolicyDialogOpened: (isOpen: boolean) => void;
}

interface NewUserFormsProps {
  newUser: NewUser;
  setNewUser: (newUser: NewUser) => void;
  accepted: boolean;
  setAccepted: (accepted: boolean) => void;
  setIsPrivacyPolicyDialogOpened: (isOpen: boolean) => void;
}

const NewUserForms: FunctionComponent<NewUserFormsProps> = (props) => {
  const {
    newUser,
    setNewUser,
    accepted,
    setAccepted,
    setIsPrivacyPolicyDialogOpened
  } = props;

  return <>
    <div className="l-is-company-switch-container">
      <Switch
        className="l-is-company-switch"
        nativeControlId="is-company-switch"
        checked={newUser.isCompany}
        onChange={(e: any) => setNewUser({
          ...newUser,
          isCompany: e.target.checked,
          isAlumni: e.target.checked ? false : newUser.isAlumni,
        })} />
      <label
        className={newUser.isCompany
          ? "l-is-company-switch-label mdc-form-field"
          : "l-is-company-switch-label mdc-form-field s-is-company-switch-label-inactive"
        }
        htmlFor="is-company-swith"
      >
        Cadastro para empresa
      </label>
    </div>

    <TextField
      label={newUser.isCompany ? "Razão Social" : "Nome completo"}
    >
      <Input
        isValid={true}
        value={newUser.name ? newUser.name : ""}
        onChange={(e: any) => setNewUser({...newUser, name: e.currentTarget.value})}
      />
    </TextField>

    <TextField
      label={newUser.isCompany ? "Nome fantasia" : "Como você gostaria de ser chamado?"}
    >
      <Input
        value={newUser.nickname ? newUser.nickname : ""}
        onChange={(e: any) => setNewUser({...newUser, nickname: e.currentTarget.value})}
      />
    </TextField>

    <TextField
      label={newUser.isCompany ? "CNPJ" : "CPF"}
    >
      <Input
        isValid={newUser.document
          ? newUser.document.length === 11 || newUser.document.length === 14
          : true
        }
        value={newUser.document ? newUser.document : ""}
        onChange={(e: any) => setNewUser({...newUser, document: e.currentTarget.value})}
      />
    </TextField>

    <TextField
      label="Email"
    >
      <Input
        type="email"
        isValid={newUser.email
          ? newUser.email.includes("@")
          : true
        }
        value={newUser.email ? newUser.email : ""}
        onChange={(e: FormEvent<HTMLInputElement>) => {
          e.preventDefault();
          e.stopPropagation();

          setNewUser({...newUser, email: e.currentTarget.value})
        }}
      />
    </TextField>

    <TextField
      label="Senha da área do doador"
      helperText={<HelperText>Para acesso à área do doador</HelperText>}
    >
      <Input
        type="password"
        value={newUser.password ? newUser.password : ""}
        onChange={(e: FormEvent<HTMLInputElement>) => {
          e.preventDefault();
          e.stopPropagation();

          setNewUser({...newUser, password: e.currentTarget.value})
        }}
      />
    </TextField>

    <div className="m-checkbox-container">
      <Checkbox
        className="m-checkbox-element"
        nativeControlId="privacy-policy-check"
        checked={newUser.receiveNewsletter}
        onChange={(e) => {
          setNewUser({...newUser, receiveNewsletter: e.target.checked})
        }}
      />
      <div className="m-checkbox-element">
        Desejo receber emails informativos quanto a projetos do Endowment
      </div>
    </div>

    {!newUser.isCompany &&
      <div className="m-checkbox-container">
        <Checkbox
          className="m-checkbox-element"
          nativeControlId="privacy-policy-check"
          checked={newUser.isAlumni}
          onChange={(e) => {
            setNewUser({...newUser, isAlumni: e.target.checked})
          }}
        />
        <div className="m-checkbox-element">
          Sou alumni
        </div>
      </div>
    }

    {newUser.isAlumni &&
      <TextField
        className="m-text-small-input"
        label="Turma"
      >
        <Input
          value={newUser.year}
          onChange={(e: any) => setNewUser({...newUser, year: e.currentTarget.value})}
        />
      </TextField>
    }

    <div className="m-checkbox-container l-privacy-policy-check-container">
      <Checkbox
        className="m-checkbox-element"
        nativeControlId="privacy-policy-check"
        checked={accepted}
        onChange={(e) => {
          setAccepted(e.target.checked);
        }}
      />
      <div className="m-checkbox-element">
        Aceitar os <a onClick={() => setIsPrivacyPolicyDialogOpened(true)} href="#">termos de uso e política de privacidade</a>.
      </div>
    </div>
  </>
}

const UserData: FunctionComponent<UserDataProps> = (props) => {
  const {
    mainOption,
    setMainOption,
    newUser,
    setNewUser,
    accepted,
    setAccepted,
    loginData,
    setLoginData,
    setIsLoginResetDialogOpen,
    setIsPrivacyPolicyDialogOpened,
  } = props;

  return <>
    <Radio label="É minha primeira doação" key="first-donation">
      <NativeRadioControl
        name="first-donation"
        value="create-user"
        id="first-donation-radio"
        checked={mainOption === "create-user"}
        onChange={(e: any) => setMainOption(e.target.value)}
      />
    </Radio>
    <Radio label="Já sou doador" key="login">
      <NativeRadioControl
        name="login"
        value="login"
        id="login-radio"
        checked={mainOption === "login"}
        onChange={(e: any) => {
          setMainOption(e.target.value)
        }}
      />
    </Radio>

    {mainOption === "create-user" && <NewUserForms
      newUser={newUser}
      setNewUser={setNewUser}
      accepted={accepted}
      setAccepted={setAccepted}
      setIsPrivacyPolicyDialogOpened={setIsPrivacyPolicyDialogOpened}
    />}
    {mainOption === "login" && <>
      <Login loginData={loginData} setLoginData={setLoginData}/>
      
      <a className="l-reset-password-a" href="#login-reset-dialog" onClick={async (e) => {
        setIsLoginResetDialogOpen(true);
      }}>Esqueci minha senha</a>
    </>}
  </>
}

export default UserData;
