import React, { FunctionComponent, useState } from "react";
import { Campaign } from "../../entities/Campaign";
import { app } from "firebase";
import LoadingSetter from "../LoadingSetter";
import "./CampaignCard.scss";
import Divider from "./Divider";
import TextField, { Input } from "@material/react-text-field";
import { Box } from "@mui/material";
import { styled } from '@mui/material/styles';

const DoubleBox = styled(Box)`
    display: flex;
    margin-bottom: 5px
    width: '100%';
    justify-content: space-between;
`

interface CampaignCardProps {
    firebase: app.App;
    setLoading: LoadingSetter;
    loading: string[];
    campaign: Campaign;
    className?: string;
}

const CampaignCard: FunctionComponent<CampaignCardProps> = (props) => {
    const { firebase, setLoading, campaign, className, loading } = props;
    const { name, begin_date, end_date, goal_amount, is_monthly_goal, timestamp } = campaign;

    let classes: string = className ? `m-crm-trigger ${className}` : "m-crm-trigger";
    function processIsMonthlyGoal() {
        if (is_monthly_goal) { return "Sim" } else return "Não"
    }
    return <div className={classes}>
        <h4>{name}</h4>

        <Divider />
        <DoubleBox>

            <div className="m-subject-input">
                <TextField outlined label="Data de Início">
                    <Input
                        value={begin_date}
                    />
                </TextField>
            </div>

            <div className="m-subject-input">
                <TextField outlined label="Data do Fim">
                    <Input
                        value={end_date}
                    />
                </TextField>
            </div>

            <div className="m-subject-input">
                <TextField outlined label="Foco em Arrecadação Recorrente">
                    <Input
                        value={processIsMonthlyGoal()}
                    />
                </TextField>
            </div>
        </DoubleBox>
        <DoubleBox>

            <div className="m-subject-input">
                <TextField outlined label="Valor Alvo (em reais)">
                    <Input
                        value={goal_amount}
                    />
                </TextField>
            </div>

            <div className="m-subject-input">
                <TextField outlined label="Criada em:">
                    <Input
                        value={timestamp}
                    />
                </TextField>
            </div>
        </DoubleBox>

        <Divider />

    </div>
}

export default CampaignCard;
