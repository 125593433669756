import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

const ChartTitle = styled(Typography)`
    font-size: 30px;
    color: #35b0b8;
    text-align: center;
`
export default class ClassesRankingBarChart extends PureComponent {
    static demoUrl = 'https://codesandbox.io/s/simple-bar-chart-tpz8r';
    static data: Object[];
    constructor(props: any) {
        super(_);
        if (props.classesRankDataClean) {
            ClassesRankingBarChart.data = [].slice.call(props.classesRankDataClean).sort((n1: any, n2: any) => {
                if (n1["Valor Arrecadado"] > n2["Valor Arrecadado"]) {
                    return -1;
                }

                if (n1["Valor Arrecadado"] < n2["Valor Arrecadado"]) {
                    return 1;
                }

                return 0;
            });
        }
        else {
            ClassesRankingBarChart.data = []
        }
    }


    render() {
        return (
            <Box sx={{ width: '100%', height: '300px' }}>
                <ChartTitle>Ranking de Turmas</ChartTitle>
                <ResponsiveContainer minWidth="160px" minHeight="160px" height='100%' width='100%'>
                    <BarChart
                        width={500}
                        height={300}
                        data={ClassesRankingBarChart.data.slice(0, 5)}
                        margin={{
                            top: 5,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="6 6" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Valor Arrecadado" fill="#35b0b8">
                            <LabelList dataKey="name" position="top" />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </Box >

        );
    }
}

function _(_: any) {
    throw new Error('Function not implemented.');
}
