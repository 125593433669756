import React, {FunctionComponent, useState} from "react";
import {CrmTrigger} from "../../entities/Crm";
import { app } from "firebase";
import LoadingSetter from "../LoadingSetter";
import "./CrmTriggerCard.scss";
import Divider from "./Divider";
import { Button } from "@material/react-button";
import Switch from "@material/react-switch";
import TextField, { Input } from "@material/react-text-field";

interface CrmTriggerCardProps {
  firebase: app.App;
  setLoading: LoadingSetter;
  loading: string[];
  trigger: CrmTrigger;
  className?: string;
}

const CrmTriggerCard: FunctionComponent<CrmTriggerCardProps> = (props) => {
  const {firebase, setLoading, trigger, className, loading} = props;
  const {descriptionPtBr, enabled, label, template, id, subject} = trigger;
  const [updatedTemplate, setUpdatedTemplate] = useState<string>(template);
  const [updatedEnabled, setUpdatedEnabled] = useState<boolean | null>(null);
  const [updatedSubject, setUpdatedSubject] = useState<string>(subject);
  const triggerCollection = firebase.firestore().collection("crm-triggers");
  const hasUpdates = updatedSubject !== subject || updatedTemplate !== template || updatedEnabled !== null;

  const updateTrigger = async () => {
    setLoading("trigger-update");
    try {
      const updates = trigger;
      if (updatedEnabled !== null) {
        updates.enabled = updatedEnabled;
      }
      updates.template = updatedTemplate.trim();
      updates.subject = updatedSubject;

      await triggerCollection.doc(id).update(updates);
      setUpdatedEnabled(null);
    } catch(err) {
      console.error(err);
      alert("Não foi possível atualizar o gatilho no banco de dados");
    } finally {
      setLoading("trigger-update", false);
      alert("Gatilho atualizado com sucesso");
    }
  }
  let classes: string = className ? `m-crm-trigger ${className}` : "m-crm-trigger";
  if (hasUpdates) {
    classes += " s-card-to-update"
  }

  return <div className={classes}>
    <h4>{descriptionPtBr}</h4>

    <Divider/>

    <div className="m-subject-input">
      <TextField outlined label="Assunto do email">
        <Input
          value={updatedSubject}
          onChange={(e: any) => setUpdatedSubject(e.currentTarget.value)}
        />
      </TextField>
    </div>
    
    <div  className="m-subject-input">
      <TextField outlined label="Nome do template no Mailgun">
        <Input
          value={updatedTemplate}
          onChange={(e: any) => setUpdatedTemplate(e.currentTarget.value)}
        />
      </TextField>
    </div>
    

    <Divider/>

    <Button
      disabled={ !hasUpdates || loading.includes("trigger-update") }
      onClick={updateTrigger}
      outlined={true}
    >Atualizar</Button>

    {label !== "base-event" &&
      <Button>Abortar</Button>
    }

    {label === "base-event" && <div className="m-enable-trigger">
      <span>{
        (typeof updatedEnabled === "boolean" ? updatedEnabled : enabled)
          ? "Habilitado :"
          : "Desabilitado :"
      }</span>
      <Switch
        className="m-enable-switch"
        onChange={(e: any) => {
          setUpdatedEnabled(e.target.checked)
        }}
        checked={typeof updatedEnabled === "boolean" ? updatedEnabled : enabled}
      />
    </div>}

  </div>
}

export default CrmTriggerCard;
