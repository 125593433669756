import React, { FunctionComponent, useState } from "react";
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from '@material/react-dialog';
import { app, User } from "firebase";
import "firebase/firestore";
import Login, { LoginData, LoginButton } from "../../components/forms/Login";
import { AdminRole, Admin as IAdmin } from "../../../entities/Admin";
import AdminDrawer, { AdminPage } from "../../components/AdminDrawer";
import LoadingSetter from "../../LoadingSetter";
import AdminPageContent from "./AdminPageContent";
import "./index.scss";


interface AdminProps {
  firebase: app.App;
  firebaseUser: User | null;
  setLoading: LoadingSetter;
  loading: string[];
}

const Admin: FunctionComponent<AdminProps> = (props) => {
  const { firebase, firebaseUser, setLoading, loading } = props;
  const [loginData, setLoginData] = useState<LoginData>({
    email: "",
    password: "",
  });
  const [role, setRole] = useState<AdminRole | null>(null);
  const [adminPage, setAdminPage] = useState<AdminPage>("dashboard");
  const db = firebase.firestore();

  if (firebaseUser !== null) {
    if (role === null) {
      setRole("");
      const docRef = db.collection("admins").doc(firebaseUser.uid);
      docRef.get().then(doc => {
        const admin = doc.data() as IAdmin;
        if (typeof admin.role === "string" && admin.role !== "") {
          setRole(admin.role);
        }
      }).catch((err) => {
        console.log(err);
        window.location.replace("/area-do-doador");
      });
    }
  }

  return <>
    <Dialog
      open={firebaseUser === null}
      escapeKeyAction=""
      role="dialog"
      scrimClickAction=""
    >
      <DialogTitle>Login</DialogTitle>
      <DialogContent>
        <Login loginData={loginData} setLoginData={setLoginData} />
      </DialogContent>
      <DialogFooter>
        <LoginButton
          firebase={firebase}
          loginData={loginData}
          loading={loading}
          setLoading={setLoading}
          errorHandler={(msg) => alert(msg)}
        />
      </DialogFooter>
    </Dialog>

    {role !== null && role !== "" && <div className="l-admin-page">
      <AdminDrawer
        setLoading={setLoading}
        firebase={firebase}
        setAdminPage={setAdminPage}
        adminPage={adminPage}
      />
      <AdminPageContent
        firebase={firebase}
        setLoading={setLoading}
        loading={loading}
        adminPage={adminPage}
      />
    </div>}
  </>;
}

export default Admin;