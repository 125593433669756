import React, {FunctionComponent, useState} from "react";
import User from "../../../../entities/User";
import { app } from "firebase";
import { ErrorCode } from "../../../Result";
import LoadingSetter from "../../../LoadingSetter";
import TextField, { Input } from "@material/react-text-field";
import Switch from "@material/react-switch";
import Address from "../../../../entities/Address";
import { Button } from "@material/react-button";
import { Snackbar } from "@material/react-snackbar";
import { cnpj, cpf } from "cpf-cnpj-validator";


interface UserDataUpdateProps {
  user: User | null;
  setUser: (user: User | null) => void;
  firebase: app.App;
  errorHandler: (code: ErrorCode) => void;
  setLoading: LoadingSetter;
  adminActions?: boolean;
}

type UpdateResult = {success: boolean, error?: undefined} | {success: false; error: ErrorCode}


const UserDataUpdate: FunctionComponent<UserDataUpdateProps> = (props) => {
  const {user, setLoading, firebase, errorHandler, setUser, adminActions} = props;
  const [updates, setUpdates] = useState<Partial<User>>({});
  const [addressUpdates, setAddressUpdates] = useState<Partial<Address>>({});
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState<boolean>(false);
  const [
    showPasswordResetConfirmation,
    setShowPasswordResetConfirmation
  ] = useState<boolean>(false);
  const collection = firebase.firestore().collection("users");
  const auth = firebase.auth();

  const updateUserData = async (): Promise<UpdateResult> => {
    const {isCompany, document, email, name, isAlumni, year, nickname} = updates;

    if (isCompany && typeof document === "string" && !cnpj.isValid(document)) {
      return {success: false, error: "invalid-cnpj"};
    }
    if (!isCompany && typeof document === "string" && !cpf.isValid(document)) {
      return {success: false, error: "invalid-cpf"};;
    }
    if (typeof email === "string" && !email.includes("@")) {
      return {success: false, error: "invalid-email"};;
    }
    if (typeof name === "string" && name?.length < 3) {
      return {success: false, error: "invalid-name"};;
    }

    try {
      if (user) {
        await collection.doc(user.id).update({
          ...updates,
          address: {
            ...user.address,
            ...addressUpdates,
          } as Partial<Address>,
        });
        if (nickname && !adminActions) {
          await auth.currentUser?.updateProfile({
            displayName: nickname,
          });
        }
        setUser((await collection.doc(user.id).get()).data() as unknown as User);
      } else {
        return {success: false, error: "auth-fail"};
      }
    } catch(err) {
      console.error(err);
      return {success: false, error: "internal-error"};
    }

    return {success: true};
  }
  
  if (user === null) {
    return null
  }
  return <>

    <h3>Informações básicas:</h3>

    <TextField
      label={user.isCompany ? "Razão Social" : "Nome completo"}
    >
      <Input
        isValid={true}
        value={typeof updates.name === "string" ? updates.name : user.name}
        onChange={(e: any) => setUpdates({...updates, name: e.currentTarget.value})}
      />
    </TextField>

    <TextField
      label={user.isCompany ? "Nome fantasia" : "Como você gostaria de ser chamado?"}
    >
      <Input
        value={typeof updates.nickname === "string" ? updates.nickname : user.nickname}
        onChange={(e: any) => setUpdates({...updates, nickname: e.currentTarget.value})}
      />
    </TextField>

    <TextField
      label={user.isCompany ? "CNPJ" : "CPF"}
    >
      <Input
        isValid={updates.document
          ? updates.document.length === 11 || updates.document.length === 14
          : true
        }
        value={typeof updates.document === "string" ? updates.document : user.document}
        onChange={(e: any) => setUpdates({...updates, document: e.currentTarget.value})}
      />
    </TextField>

    <TextField
      label="Telefone"
    >
      <Input
        value={typeof updates.phone === "string" ? updates.phone : user.phone}
        onChange={(e: any) => setUpdates({...updates, phone: e.currentTarget.value})}
      />
    </TextField>

    <TextField
      label="Email"
    >
      <Input
        type="email"
        disabled={true}
        isValid={updates.email
          ? updates.email.includes("@")
          : true
        }
        value={typeof updates.email === "string" ? updates.email : user.email}
        onChange={(e: any) => setUpdates({...updates, email: e.currentTarget.value})}
      />
    </TextField>

    <ul className="l-user-data-update-list">
      <li>Receber emails informativos quanto a projetos do Endowment:
        <Switch
          className="m-user-update-forms-switch"
          checked={typeof updates.receiveNewsletter === "boolean" ? updates.receiveNewsletter : user.receiveNewsletter}
          onChange={(e: any) => {
            setUpdates({
              ...updates,
              receiveNewsletter: e.target.checked,
            });
          }
        }/>
      </li>
      <li>
        Senha: <a
          href="#update-button"
          onClick={async () => {
            setLoading("password-reset");
            try {
              await firebase.auth().sendPasswordResetEmail(user.email);
              setShowPasswordResetConfirmation(true);
            } catch(err) {
              console.error(err);
              errorHandler("internal-error");
            } finally {
              setLoading("password-reset", false);
            }
          }}  
        >solicitar alteração de senha</a>
      </li>
    </ul>

    <h3>Endereço:</h3>

    <TextField
      label="Rua"
    >
      <Input
        value={typeof addressUpdates.street === "string" ? addressUpdates.street : user.address?.street}
        onChange={(e: any) => setAddressUpdates({...addressUpdates, street: e.currentTarget.value})}
      />
    </TextField>

    <TextField
      label="Número"
    >
      <Input
        value={typeof addressUpdates.number === "string" ? addressUpdates.number : user.address?.number}
        onChange={(e: any) => setAddressUpdates({...addressUpdates, number: e.currentTarget.value})}
      />
    </TextField>

    <TextField
      label="Complemento"
    >
      <Input
        value={typeof addressUpdates.complement === "string" ? addressUpdates.complement : user.address?.complement}
        onChange={(e: any) => setAddressUpdates({...addressUpdates, complement: e.currentTarget.value})}
      />
    </TextField>

    <TextField
      label="Bairro"
    >
      <Input
        value={typeof addressUpdates.district === "string" ? addressUpdates.district : user.address?.district}
        onChange={(e: any) => setAddressUpdates({...addressUpdates, district: e.currentTarget.value})}
      />
    </TextField>

    <TextField
      label="CEP / Código postal"
    >
      <Input
        value={typeof addressUpdates.postalCode === "string" ? addressUpdates.postalCode : user.address?.postalCode}
        onChange={(e: any) => setAddressUpdates({...addressUpdates, postalCode: e.currentTarget.value})}
      />
    </TextField>

    <TextField
      label="Cidade"
    >
      <Input
        value={typeof addressUpdates.city === "string" ? addressUpdates.city : user.address?.city}
        onChange={(e: any) => setAddressUpdates({...addressUpdates, city: e.currentTarget.value})}
      />
    </TextField>

    <TextField
      label="Estado"
    >
      <Input
        value={typeof addressUpdates.state === "string" ? addressUpdates.state : user.address?.state}
        onChange={(e: any) => setAddressUpdates({...addressUpdates, state: e.currentTarget.value})}
      />
    </TextField>

    <TextField
      label="País"
    >
      <Input
        value={typeof addressUpdates.contry === "string" ? addressUpdates.contry : user.address?.contry}
        onChange={(e: any) => setAddressUpdates({...addressUpdates, contry: e.currentTarget.value})}
      />
    </TextField>

    {user.isAlumni && <>
      <h3>Sou Alumni: <Switch
        className="m-user-update-forms-switch"
        checked={typeof updates.isAlumni === "boolean" ? updates.isAlumni : user.isAlumni}
        onChange={(e: any) => {
          setUpdates({
            ...updates,
            isAlumni: e.target.checked,
          });
        }
      }/></h3>
      <TextField
        label="Turma"
      >
        <Input
          disabled={typeof updates.isAlumni === "boolean" ? !updates.isAlumni : !user.isAlumni}
          value={updates.year !== undefined ? updates.year : user.year}
          onChange={(e: any) => setUpdates({...updates, year: e.currentTarget.value})}
        />
      </TextField>
    </>}

    <Button
      id="update-button"
      className="m-centralize"
      onClick={async () => {
        setLoading("update-user-data");
        try {
          const {success, error} = await updateUserData();
          if (success) {
            setShowUpdateConfirmation(true);
          } else {
            errorHandler(error as ErrorCode);
          }
        } catch(err) {
          console.error(err);
          errorHandler("internal-error");
        } finally {
          setLoading("update-user-data", false);
        }
      }}
    >Atualizar</Button>

    {showUpdateConfirmation &&
      <Snackbar
        message="Atualização feita com sucesso"
        actionText="Fechar"
        onClose={() => {
          setShowUpdateConfirmation(false);
        }}
      />
    }
    {showPasswordResetConfirmation &&
      <Snackbar
        message={`Email de redefinição de senha enviado para ${user.email}`}
        actionText="Fechar"
        onClose={() => {
          setShowPasswordResetConfirmation(false);
        }}
      />
    }
  </>
}

export default UserDataUpdate;
