export interface Config {
  functionsUrl: string;
  useSandbox: boolean;
  junoPublicToken: string;
  minAmount: number;
  firestoreHost?: string;
  authEmulatorHost?: string;
}

const { hostname } = window.location;

const config: Config = typeof process.env.NODE_ENV === "string" && process.env.NODE_ENV.toLowerCase() === "development"
  ? {
    functionsUrl: `http://${hostname}:5001/endowment-fmusp/us-central1`,
    useSandbox: true,
    junoPublicToken: "ABF193461862F04581637CC20039ED65A9348CC43497F8F3C09C2AD48AA0CC38",
    minAmount: 10,
    firestoreHost: `${hostname}:8080`,
    authEmulatorHost: `http://${hostname}:9099`,
  }
  : {
    functionsUrl: "https://us-central1-endowment-fmusp.cloudfunctions.net",
    useSandbox: false,
    junoPublicToken: "F51AEF7310C40768946D8063F649B9B016FE6A4594733F11E0F9664D0AB4F1A885EA2221D07F371F",
    minAmount: 10,
  };


export default config;
