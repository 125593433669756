import React, { FunctionComponent, useState, ReactElement, useEffect } from "react";
import {app, User} from "firebase/app";
import * as steps from "../../components/forms/NewDonationSteps";
import {UserDataMainOption, NewUser} from "../../components/forms/NewDonationSteps";
import Button from "@material/react-button";
import MaterialIcon from "@material/react-material-icon";
import Donation from "../../../entities/Donation";
import "./NewDonation.scss";
import preactions, {StepName} from "./StepChangePreations";
import UserData from "../../../entities/User";
import Address from "../../../entities/Address";
import {NewCard} from "../../services/paymentGateway";
import { LoginData } from "../../components/forms/Login";
import {DonationCreationRequest, DonationRequestWithHash, DonationRequestWithId} from "../../../entities/Donation";
import {AxiosError} from "axios";
import {ErrorCode} from "../../Result";
import { Payment, CreditCardWithId } from "../../../entities/Payment";
import functions from "./../../services/functions";
import LoadingSetter from "../../LoadingSetter";
import { Chip } from "@material/react-chips";
import Result from "../../Result";
import config from "../../config";
import juno from "../../services/paymentGateway";
import availablePaymentDays from "../../availablePaymentDays";

const { minAmount } = config;

interface NewDonationProps {
  firebase: app.App;
  firebaseUser?: User | null;
  donation: Partial<Donation>;
  setDonation: (donation: Partial<Donation>) => void;
  loading: string[];
  setLoading: LoadingSetter;
  user: UserData | null;
  setUser: (user: UserData | null) => void;
  currentStep: StepName;
  setCurrentStep: (stepName: StepName) => void;
  setIsLoginResetDialogOpen: (isOpen: boolean) => void;
  setIsPrivacyPolicyDialogOpen: (isOpen: boolean) => void;
  setErrorToDisplay: (error: ErrorCode | null) => void;
  setSuccessfulDonation: (success: boolean) => void;
  setErrorDetails: (details: string[]) => void;
}

const stepNamePtBr = new Map<StepName, string>([
  ["amount", "Valor doado"],
  ["user-data", "próximo"],
  ["new-credit-card", "Adicionar cartão"],
  ["payment", "Pagamento"],
  ["additional-info", "Informações adicionais"],
]);

const stepTitlePtBr = new Map<StepName, string>([
  ["amount", "Faça sua doação"],
  ["user-data", "Dados do doador"],
  ["new-credit-card", "Doar com um novo cartão"],
  ["payment", "Método de pagamento"],
  ["additional-info", "Agradecimento"]
]);


const sendNewDonation = async (
  donationData: DonationCreationRequest,
  errorSetter: (code: ErrorCode) => void,
  successSetter: (success: boolean) => void,
  errorDetailsSetter: (details: string[]) => void,
) => {
  try {
    const boletoUri = await functions.newDonation(donationData);
    successSetter(true);
    if (boletoUri) {
      await juno.downloadBoleto(boletoUri);
    }
  } catch(err) {
    if (err.isAxiosError) {
      const axiosError = err as AxiosError;
      if(axiosError.response?.status === 400) {
        switch(axiosError.response?.data?.invalidField) {
          case "amount":
            errorSetter("invalid-amount");
            return;
          case "creditCardHash":
            errorSetter("invalid-payment-method");
            return;
          case "paymentMethod.type":
            errorSetter("invalid-payment-method");
            return;
          case "userId":
            errorSetter("auth-fail");
            return;
          default:
            errorSetter("internal-error");
            return;
        }
      } else if([401, 403].includes(axiosError.response?.status as number)) {
        errorSetter("auth-fail");
      } else if (axiosError.response?.status === 422) {
        const { details } = axiosError.response.data;
        errorDetailsSetter(details instanceof Array ? details : []);
        errorSetter("payment-error");
      } else {
        errorSetter("internal-error");
      }
    } else if (err.isMissingToken) {
      errorSetter("auth-fail");
    }
  }
}


const setSubscription = async (
  donation: Partial<Donation>,
  firebase: app.App,
  user: UserData,
  errorSetter: (code: ErrorCode) => void,
  successSetter: (success: boolean) => void,
): Promise<void> => {
  if (!donation.userId) {
    console.error("Null userId");
    errorSetter("auth-fail");
    return;
  }

  let paymentType = donation.payment?.type;
  if (!paymentType) {
    console.error("Invalid payment type");
    errorSetter("invalid-payment-method");
    return;
  }
  if (!availablePaymentDays.includes(donation.paymentDay as number)) {
    console.error("Invalid payment day ", donation.paymentDay);
    errorSetter("internal-error");
    return;
  }

  const collection = firebase.firestore().collection("users");
  const doc = collection.doc(donation.userId);
  const now = new Date();
  await doc.update({
    isMonthlySubscribed: true,
    monthlySubscription: {
      amount: donation.amount,
      paymentType,
      creditCard: paymentType === "CREDIT_CARD"
        ? user.creditCards[user.defaultCreditCardIndex]
        : undefined,
      message: donation.message,
      lastDonationDate: user.monthlySubscription?.lastDonationDate,
      paymentDay: donation.paymentDay,
      whoToThanks: donation.whoToThanks,
      startDate: now.toISOString(),
      isInMemoriam: donation.isInMemoriam,
      guild: donation.guild,
    },
  } as Partial<UserData>);
  successSetter(true);
  return;
}

const NewDonation: FunctionComponent<NewDonationProps> = (props) => {
  const {
    firebase,
    donation,
    setDonation,
    firebaseUser,
    loading,
    setLoading,
    user,
    setUser,
    currentStep,
    setCurrentStep,
    setIsLoginResetDialogOpen,
    setIsPrivacyPolicyDialogOpen: setIsPrivacyPolicyDialogOpened,
    setErrorToDisplay,
    setSuccessfulDonation,
    setErrorDetails,
  } = props;
  const [selectedMethod, setSelectedMethod] = useState<Payment>(user && user.defaultCreditCardIndex > -1
    ? {type: "CREDIT_CARD", cardIndx: user.defaultCreditCardIndex}
    : {type: "CREDIT_CARD", cardIndx: null}
  );
  const [userDataMainOption, setUserDataMainOption] = useState<UserDataMainOption>("create-user");
  const [newUser, setNewUser] = useState<NewUser>({
    name: "",
    nickname: "",
    document: "",
    email: "",
    receiveNewsletter: true,
    isAlumni: false,
    year: "",
    isCompany: false,
    password: "",
  });
  const [accepted, setAccepted] = useState(false);
  const [newCard, setNewCard] = useState<Partial<NewCard>>({});
  const [loginData, setLoginData] = useState<LoginData>({
    email: "",
    password: "",
  });
  const displayName = firebase.auth().currentUser?.displayName;
  const [anonymousCreditCardHash, setAnonymousCreditCardHash] = useState<string | null>(null);
  const [isToSaveAddress, setIsToSaveAddress] = useState<boolean>(!user?.address);
  let stepElement: ReactElement<any, any>;
  let lastStep: StepName | null = null;
  let nextStep: StepName | null = null;
  let nextStepEnabled = false;

  useEffect(() => {
    setDonation({
      ...donation,
      payment: selectedMethod
    })
  }, [selectedMethod]);

  useEffect(() => {
    if(user !== null && user.defaultCreditCardIndex > -1) {
      const {last4digits, company} = user.creditCards[user.defaultCreditCardIndex];
      setSelectedMethod({
        type: "CREDIT_CARD",
        cardIndx: user.defaultCreditCardIndex,
        last4digits,
        company,
      });
    }
  }, [user === null]);

  switch(currentStep) {
    case "user-data":
      stepElement = <steps.UserData
        mainOption={userDataMainOption}
        setMainOption={setUserDataMainOption}
        newUser={newUser}
        setNewUser={setNewUser}
        accepted={accepted}
        setAccepted={setAccepted}
        loginData={loginData}
        setLoginData={setLoginData}
        donation={donation}
        setDonation={setDonation}
        setIsLoginResetDialogOpen={setIsLoginResetDialogOpen}
        setIsPrivacyPolicyDialogOpened={setIsPrivacyPolicyDialogOpened}
      />;
      lastStep = "amount";
      nextStep = "payment";
      const canCreateNewUser: boolean = !loading.includes("new-user-creation");
      nextStepEnabled = canCreateNewUser || userDataMainOption === "login" || userDataMainOption === "anonymous";
      window.history.pushState({}, "user-data", "dados-do-doador");
      break;
    case "payment":
      stepElement = <steps.Payment
        donation={donation}
        firebase={firebase}
        selectedMethod={selectedMethod}
        setSelectedMethod={setSelectedMethod}
        user={user}
      />;
      lastStep = firebaseUser ? "amount" : "user-data";
      nextStep = selectedMethod.type === "CREDIT_CARD" && selectedMethod.cardIndx === null ? "new-credit-card" : "additional-info";
      nextStepEnabled = true;
      window.history.pushState({}, "payment", "pagamento");
      break;
    case "new-credit-card":
      stepElement = <steps.NewCreditCard
        newCard={newCard}
        setNewCard={setNewCard}
        isTosaveAddress={isToSaveAddress}
        setIsTosaveAddress={setIsToSaveAddress}  
      />;
      lastStep = "payment";
      nextStep = "additional-info";
      nextStepEnabled = true;
      window.history.pushState({}, "new-credit-card", "novo-cartao");
      break;
    case "additional-info":
      stepElement = <steps.AdditionalInfo
        firebase={firebase}
        donation={donation}
        setDonation={setDonation}
        setCurrentStep={setCurrentStep}
        selectedMethod={selectedMethod}
        user={user}
        setLoading={setLoading}
      />;
      lastStep = "payment";
      nextStepEnabled = true;
      window.history.pushState({}, "new-credit-card", "novo-cartao");
      break;
    default:
      stepElement = <steps.Amount
        user={user}
        firebase={firebase}
        donation={donation}
        setDonation={setDonation}
      />;
      nextStep = firebaseUser ? "payment" : "user-data";
      nextStepEnabled = (donation.amount as number) >= minAmount;
      window.history.pushState({}, "amount", "doe");
  }

  return <>

    {user &&
      <Chip
        className="l-login-data-chip"
        label={`${user.nickname || user.name.split(" ")[0]}`}
        trailingIcon={
          <MaterialIcon
            icon="exit_to_app"
            onClick={() => {
              firebase.auth().signOut();
              window.location.reload();
            }}
          />
        }
      />
    }

    {lastStep &&
      <Button
        onClick={() => {
          window.history.back();
          setCurrentStep(lastStep as StepName);
        }}
        icon={<MaterialIcon
          role="button"
          icon="keyboard_arrow_left"
        />}
      >{stepNamePtBr.get(lastStep)}</Button>
    }
    <h3>{displayName && currentStep === "amount"
      ? `Olá ${displayName}, deseja fazer uma nova doação?`
      : stepTitlePtBr.get(currentStep)
    }</h3>
    {stepElement}
    {nextStep
    ?
    <Button
      className="l-next-step-button"
      outlined={true}
      disabled={!nextStepEnabled}
      onClick={async () => {
        const preaction = preactions.get(currentStep);
        const {success, error} = (preaction && await preaction({
          donation,
          newUser,
          selectedMethod,
          userDataMainOption,
          firebase,
          setLoading,
          newCard,
          user,
          setUser,
          setSelectedMethod,
          loginData,
          setAnonymousCreditCardHash,
          acceptedPrivacyPolicy: accepted,
          isToSaveAddress,
        })) as Result;
        if (success) {
          setCurrentStep(nextStep as StepName);
        } else {
          setErrorToDisplay(error ? error : null);
        }
      }}
      trailingIcon={<MaterialIcon
        role="button"
        icon="keyboard_arrow_right"
      />}
    >{stepNamePtBr.get(nextStep)}</Button>
    :
    <Button
      className="l-donation-confirmation-button"
      outlined={true}
      raised={true}
      disabled={!nextStepEnabled || loading.length > 0}
      trailingIcon={<MaterialIcon
        role="button"
        icon="done"
      />}
      onClick={async () => {
        const isAnonymous = userDataMainOption === "anonymous";

        setLoading("new-donation");
        try {
          if (donation.type === "MONTHLY") {
            if (user === null) {
              throw(new Error("Null user"));
            }
            await setSubscription(
              donation,
              firebase,
              user,
              setErrorToDisplay,
              setSuccessfulDonation
            );
          } else if (donation.type === "SINGLE") {
            let donationRequest: DonationCreationRequest = {
              amount: donation.amount as number,
              paymentMethod: selectedMethod,
              userId: isAnonymous ? "anonymous" : user?.id as string,
              whoToThanks: isAnonymous ? undefined : donation.whoToThanks as string,
              message: isAnonymous ? undefined : donation.message,
              isInMemoriam: donation.isInMemoriam,
              guild: donation.guild,
            };

            if (selectedMethod.type === "CREDIT_CARD") {
              if (typeof selectedMethod.cardIndx === "number" && selectedMethod.cardIndx > -1) {
                const junoId = (user?.creditCards[selectedMethod.cardIndx] as CreditCardWithId).junoId;
                (donationRequest as DonationRequestWithId).cardId = junoId;
                (donationRequest as DonationRequestWithId).billingAddress = user?.creditCards[selectedMethod.cardIndx]?.billingAddress as Address;
              } else if (anonymousCreditCardHash !== null && donation.userId === "anonymous") {
                (donationRequest as DonationRequestWithHash).cardHash = anonymousCreditCardHash;
                (donationRequest as DonationRequestWithHash).billingAddress = newCard.billingAddress as Address;
              }
            }
            await sendNewDonation(
              donationRequest as DonationCreationRequest,
              setErrorToDisplay,
              setSuccessfulDonation,
              setErrorDetails,
            );
          } else {
            throw(new Error("Invalid donation type"));
          }
        } catch(err) {
          console.error(err);
          setErrorToDisplay("internal-error");
        }
        setLoading("new-donation", false);
      }}
    >{user?.isMonthlySubscribed && donation.type === "MONTHLY" ? "Alterar doação mensal" : "Confirmar doação"}</Button> 
  }
  </>;
}

export default NewDonation;
