import {FunctionComponent, useState, ReactComponentElement, useEffect, useRef} from "react";
import { app } from "firebase";
import Donation from "../../../../entities/Donation";
import TextField, { Input } from "@material/react-text-field";
import Radio, {NativeRadioControl} from '@material/react-radio';
import {ChipSet, Chip} from "@material/react-chips";
import "../Forms.scss";
import { Payment } from "../../../../entities/Payment";
import MaterialIcon from "@material/react-material-icon";
import User from "../../../../entities/User";
import Select from '@material/react-select';
import availablePaymentDays from "../../../availablePaymentDays";
import LoadingSetter from "LoadingSetter";

interface AdditionalInfoProps {
  firebase: app.App;
  donation: Partial<Donation>;
  setDonation: (donation: Partial<Donation>) => void;
  setCurrentStep: (step: "amount" | "payment") => void;
  selectedMethod: Payment;
  user: User | null;
  setLoading: LoadingSetter; 
}

type ThanksSomeone = "me" | "other" | "nobody" | "in-memoriam";

function getPaymentChip(onClick: (e: any) => void, method: Payment, user: User | null): ReactComponentElement<any, any> {
  if (method.type === "BOLETO") {
    return <Chip
        className="m-donation-confirmation-chips"
        trailingIcon={<MaterialIcon onClick={onClick} icon="edit"></MaterialIcon>}
        leadingIcon={<MaterialIcon icon="attach_money"></MaterialIcon>}
        label="Boleto"
      />
  } else if (method.type === "CREDIT_CARD") {
    const indx = method.cardIndx;
    const card = typeof indx === "number" ? user?.creditCards[indx] : null;
    if (card) {
      return <Chip
        className="m-donation-confirmation-chips"
        trailingIcon={<MaterialIcon onClick={onClick} icon="edit"></MaterialIcon>}
        leadingIcon={<MaterialIcon icon="credit_card"></MaterialIcon>}
        label={(card.company ? card.company : "Cartão final") + " " + card.last4digits}
      />
    } else {
      return <Chip
      className="m-donation-confirmation-chips"
      trailingIcon={<MaterialIcon onClick={onClick} icon="edit"></MaterialIcon>}
      leadingIcon={<MaterialIcon icon="credit_card"></MaterialIcon>}
      label="Crédito"
    />
    }
  } else {
    return <Chip
        className="m-donation-confirmation-chips"
        trailingIcon={<MaterialIcon onClick={onClick} icon="edit"></MaterialIcon>}
        label={"ERRO"}
      />
  }
}

const AdditionalInfo: FunctionComponent<AdditionalInfoProps> = (props) => {
  const {
    donation,
    setDonation,
    setCurrentStep,
    selectedMethod,
    user,
    firebase,
    setLoading
  } = props;
  const [availableGuilds, setAvailableGuilds] = useState<string[]>([]);
  const [thanks, setThanks] = useState<ThanksSomeone>("me");
  const isAnonymous = donation.userId === "anonymous";

  useEffect(() => {
    if (user?.isMonthlySubscribed) {
      switch(user?.monthlySubscription?.whoToThanks) {
        case "":
          setThanks("nobody");
          break;
        case undefined:
          setThanks("nobody");
          break;
        case null:
          setThanks("nobody");
          break;
        case user?.name:
          setThanks("me");
          break;
        default:
          setThanks(user.monthlySubscription?.isInMemoriam ? "in-memoriam" : "other");
      }
    }
  }, [user?.id]);
  
  useEffect(() => {
    if (!availableGuilds.length) {
      setLoading("loading-guilds");
      firebase.firestore().doc("/config/guilds").get().then(doc => {
        const guilds = doc.data()?.options;

        if (guilds instanceof Array && guilds.every(el => typeof el === "string")) {
          setAvailableGuilds(guilds);
        }
      }).catch(console.error).finally(() => setLoading("loading-guilds", false));
    }
  }, [availableGuilds]);

  return <>
    <p className="m-additional-info-p">Você permite que o Endowment torne pública a identidade do doador? Nunca divulgaremos o valor da doação.</p>
    <div>
      <Radio label="Em meu nome">
        <NativeRadioControl
          checked={!isAnonymous && thanks === "me"}
          disabled={isAnonymous}
          onChange={(e: any) => {
            setThanks("me");
            setDonation({
              ...donation,
              whoToThanks: user?.name,
            });
          }}
        />
      </Radio>
    </div>

    <div className={thanks === "other" ? "s-thanks-other-inputs" : ""}>
      <Radio label={"Em nome de terceiro" + (thanks === "other" ? ":" : "")}>
        <NativeRadioControl
          checked={thanks === "other"}
          disabled={isAnonymous}    
          onChange={(e: any) => {
            setThanks("other");
            setDonation({
              ...donation,
              isInMemoriam: false,
              whoToThanks: donation.whoToThanks === user?.name ? "" : donation.whoToThanks ?? "",
            });
          }}
        />
      </Radio>
      {thanks === "other" && <>
        <TextField
          label="Nome"
          className="m-thanks-other-text-field"
          outlined={true}
        >
          <Input
            value={donation.whoToThanks ?? ""}
            onChange={(e: any) => {
              setDonation({
                ...donation,
                whoToThanks: e.currentTarget.value,
                isInMemoriam: false,
              })
            }}
          ></Input>
        </TextField>
      </>}
    </div>
    
    <div className={thanks === "in-memoriam" ? "s-thanks-other-inputs" : ""}>
      <Radio label={"In memoriam" + (thanks === "in-memoriam" ? ":" : "")}>
        <NativeRadioControl
          checked={thanks === "in-memoriam"}
          disabled={isAnonymous}    
          onChange={(e: any) => {
            setThanks("in-memoriam");
            setDonation({
              ...donation,
              isInMemoriam: true,
              whoToThanks: donation.whoToThanks === user?.name ? "" : donation.whoToThanks ?? "",
            });
          }}
        />
      </Radio>
      {thanks === "in-memoriam" && <>
        <TextField
          label="Nome"
          className="m-thanks-other-text-field"
          outlined={true}
        >
          <Input
            value={donation.whoToThanks ?? ""}
            onChange={(e: any) => {
              setDonation({
                ...donation,
                whoToThanks: e.currentTarget.value,
                isInMemoriam: true,
              })
            }}
          ></Input>
        </TextField>
      </>}
    </div>

    <div>
      <Radio label="Doação anônima">
        <NativeRadioControl
          className="m-thanks-radio"
          checked={thanks === "nobody" || isAnonymous}
          disabled={isAnonymous}        
          onChange={(e: any) => {
            setThanks("nobody");
            setDonation({
              ...donation,
              whoToThanks: null,
            });
          }}
        />
      </Radio>
    </div>

    <h3>Gostaria de deixar uma mensagem?</h3>

    <div className="l-message-box">
      <TextField
        textarea={true}
        label="Mensagem (opcional)"
        outlined={true}
      >
        <Input
          value={donation.message}
          onChange={(e: any) => {
            const message = e.currentTarget.value.replaceAll("<", "").replaceAll(">", "");
            setDonation({
              ...donation,
              message,
            });
          }}
          disabled={isAnonymous}
        />
      </TextField>
    </div>
    
    <h3>Você deseja destinar parte da sua doação a uma agremiação da FMUSP?</h3>
    
    <p>Saiba mais em <a target="_blank" href="https://www.doemedicinausp.com.br/repasses">doemedicinausp.com.br/repasses</a>.</p>

    <Select
      className="l-donation-guild m-centralize"
      label="Agremiação"
      enhanced
      value={donation.guild ?? "Nenhuma"}
      options={["Nenhuma", ...(availableGuilds.length ? availableGuilds : [user?.monthlySubscription?.guild].filter(Boolean))]
        .map(guild => ({
          label: guild ?? "Nenhuma",
          value: guild,
          key: guild,
          onClick: () => setDonation({...donation, guild: guild === "Nenhuma" ? undefined : guild}),
      }))}
    />

    <p className="l-confirmation-text">
      <h3 className="l-confirmation-title">{
        user?.isMonthlySubscribed && donation.type === "MONTHLY"
          ? "Alterar doação mensal:"
          : "Confirmar doação:"
      }</h3>
      <ChipSet className="m-donation-confirmation-chip-set">
        <Chip
          className="m-donation-confirmation-chips"
          label={"R$ " + donation.amount?.toFixed(2).replace(".", ",") + " " +
            (donation.type === "SINGLE" ? "única" : "mensal")
          }
          trailingIcon={
            <MaterialIcon
              icon="edit"
              onClick={() => setCurrentStep("amount")}
            ></MaterialIcon>
          }
          leadingIcon={<MaterialIcon icon="attach_money"></MaterialIcon>}
        />
        {getPaymentChip(() => {setCurrentStep("payment")}, selectedMethod, user)}
      </ChipSet>
    </p>

    {donation.type === "MONTHLY" &&
      <Select
        className="l-donation-day-select"
        label="Dia da doação"
        enhanced 
        value={`${donation.paymentDay}`}
        options={availablePaymentDays.map(paymentDay => ({
          label: `Todo dia ${paymentDay}`,
          value: paymentDay,
          onClick: () => setDonation({...donation, paymentDay}),
        }))}
      />
    }
  </>
}

export default AdditionalInfo;
