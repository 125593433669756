import React, {FunctionComponent, useEffect, useState} from "react";
import { app } from "firebase";
import LoadingSetter from "../../LoadingSetter";
import { Announcement, CrmTrigger } from "../../../entities/Crm";
import CrmTriggerCard from "../../components/CrmTriggerCard";
import MaterialIcon from "@material/react-material-icon";
import TextField, { HelperText, Input } from "@material/react-text-field";
import { Button } from "@material/react-button";

export interface CRMProps {
  firebase: app.App;
  loading: string[];
  setLoading: LoadingSetter;
}

const CRM: FunctionComponent<CRMProps> = (props) => {
  const {firebase, loading, setLoading} = props;
  const [triggers, setTriggers] = useState<CrmTrigger[]>([]);
  const triggerCollection = firebase.firestore().collection("crm-triggers");
  const [annoucement, setAnnoucement] = useState<Partial<Announcement>>({});

  useEffect(() => {
    setLoading("triggers-fetch");
    triggerCollection.get().then(snapshot => {
      const received = snapshot.docs.map(doc => doc.data());
      setTriggers(received as unknown as CrmTrigger[]);
    }).catch(err => {
      console.error(err);
      alert("Não foi possível obter os gatilhos do banco de dados");
    }).finally(() => {
      setLoading("triggers-fetch", false);
    })
  }, []);

  return <>
    <h3>Anúncios e newsletter:</h3>

    <div className="l-announcements">
      <TextField
        helperText={<HelperText>Email ou lista de emails do Mailgun</HelperText>}
        label="Alvo"
      >
        <Input
          value={annoucement?.target}
          onChange={(e: any) => {
            setAnnoucement({...annoucement, target: e.currentTarget.value})
          }}
        />
      </TextField>

      <TextField
        helperText={<HelperText>Assunto do email</HelperText>}
        label="Assunto da mensagem"
      >
        <Input
          value={annoucement?.subject}
          onChange={(e: any) => {
            setAnnoucement({...annoucement, subject: e.currentTarget.value})
          }}  
        />
      </TextField>

      <TextField
        helperText={<HelperText>Nome do template criado no Mailgun</HelperText>}
        label="Nome do template"
      >
        <Input
          value={annoucement?.template}
          onChange={(e: any) => {
            setAnnoucement({...annoucement, template: e.currentTarget.value})
          }}
        />
      </TextField>

      <Button
        trailingIcon={<MaterialIcon icon="campaign" />}
        className="l-annoucement-button"
        outlined
        disabled={!!loading.length}
        onClick={async () => {
          const {target, template, subject} = annoucement;
          if (!target) {
            alert("Por favor, digite o alvo da mensagem");
            return;
          }
          if (!template) {
            alert("Por favor, digite o nome do template");
            return;
          }
          if(!subject) {
            alert("Por favor, digite o assunto da mensagem");
            return;
          }

          setLoading("new-announcement");
          try {
            const firestore = firebase.firestore();
            const collection = firestore.collection("announcements");
            await collection.add({
              target,
              template: template.trim(),
              subject,
              timestamp: (new Date()).toISOString(),
            } as Announcement);
 
            alert("Mensagem enviada");
            setAnnoucement({});
          } catch(err) {
            console.error(err);
            alert("Não foi possível enviar a mensagem");
          } finally {
            setLoading("new-announcement", false);
          }
        }}
      >
        Enviar
      </Button>
    </div>

    <h3>Gatilhos:</h3>

    {triggers.map(trigger => <CrmTriggerCard
      firebase={firebase}
      loading={loading}
      setLoading={setLoading}
      key={trigger.id}
      trigger={trigger}
      className="m-admin-update-card-section"
    />)}
  </>
}


export default CRM;
