import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import { toBrlValue } from '../helpers';
import { Typography } from '@mui/material';
const BarChartBottonBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    color: gray;
    height: 20px;
    width: 100%;
    margin-top: 10px;
`
const TextBox = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const MainText = styled(Typography)`
    font-size: 20px;
    font-weight: 700;
    color: gray;
    margin-left: 5px;
    margin-right: 5px;
`
const SecondaryText = styled(Typography)`
    font-size: 12px;
    font-weight: 300;
    color: gray
    margin-left: 5px;
    margin-right: 5px;;
`
export default function BarChartBotton(props: any) {
    return (

        <BarChartBottonBox>
            <TextBox>
                <MainText>{toBrlValue(props.goalAmount)}</MainText>
                <SecondaryText> META</SecondaryText>

            </TextBox>
            <TextBox>
                <MainText>{props.remainingDays}</MainText>
                <SecondaryText>DIAS RESTANTES</SecondaryText>

            </TextBox>
        </BarChartBottonBox>

    )
}