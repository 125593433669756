import React, {FunctionComponent, useState } from "react";
import User from "../../../../entities/User";
import { app } from "firebase";
import List, {ListItem, ListItemText} from "@material/react-list";
import MaterialIcon from "@material/react-material-icon";
import NewCreditCard from "./../NewDonationSteps/NewCreditCard";
import { CreditCard } from "../../../../entities/Payment";
import { Button } from "@material/react-button";
import { ErrorCode } from "../../../Result";
import LoadingSetter from "../../../LoadingSetter";
import functions from "../../../services/functions";
import { ActionToCofirm } from "../../ConfirmationDialog";
import paymentGateway, {NewCard} from "../../../services/paymentGateway";
import Address from "../../../../entities/Address";


interface CreditCardsProps {
  user: User;
  setUser: (user: User) => void;
  firebase: app.App;
  errorHandler: (code: ErrorCode) => void;
  setLoading: LoadingSetter;
  setActionToConfirm: (action: ActionToCofirm) => void;
}

function isExpiredCard(month: string, year: string): boolean {
  const now = new Date();
  const nowMonth = now.getMonth() + 1;
  const nowString = `${now.getFullYear()}-${nowMonth >= 10 ? nowMonth.toString() : "0" + nowMonth}`;
  console.log(nowString);
  console.log(`20${year}-${month}`)
  return nowString > `20${year}-${month}`;
}

function hasSubscriptionWithCard(user: User): boolean {
  const {isMonthlySubscribed, monthlySubscription} = user;
  return isMonthlySubscribed && monthlySubscription?.paymentType === "CREDIT_CARD";
}

const CreditCards: FunctionComponent<CreditCardsProps> = (props) => {
  const {user, firebase, errorHandler, setLoading, setUser, setActionToConfirm} = props;
  const cardTitles: string[] = user.creditCards.map(card => `${card.company
    ? card.company.charAt(0).toUpperCase() + card.company.slice(1)
    : "Carão"
  } final ${card.last4digits}` + (window.innerWidth > 400
    ? `, venc. ${card.expirationMonth}/${card.expirationYear}`
    : ""  
  ));
  const [selectedIndx, setSelectedIndx] = useState<number>(user.defaultCreditCardIndex);
  const [newCard, setNewCard] = useState<Partial<NewCard>>({});
  const [showNewCardForms, setShowNewCardForms] = useState<boolean>(false);
  const [disabledActions, setDisabledActions] = useState<boolean>(false);
  const [isToSaveAddress, setIsToSaveAddress] = useState<boolean>(!user?.address);
  const selectedCard = user.creditCards[user.defaultCreditCardIndex];

  const userCollection = firebase.firestore().collection("users");
  console.log(user.defaultCreditCardIndex)

  return <>
    {!showNewCardForms && <>
      <Button
        outlined={true}
        disabled={selectedIndx === user.defaultCreditCardIndex || !selectedCard || isExpiredCard(
          selectedCard.expirationMonth,
          selectedCard.expirationYear,
        )}
        onClick={() => {
          setActionToConfirm({
            callback: async () => {
              const updates: Partial<User> = {
                defaultCreditCardIndex: selectedIndx
              }
              setLoading("updating-default-card");
              try {
                await userCollection.doc(user.id).update(updates);
                setUser({
                  ...user,
                  ...updates,
                });
              } catch(err) {
                console.error(err);
                errorHandler("internal-error");
              } finally {
                setLoading("updating-default-card", false);
              }
            },
            cancelText: "Cancelar",
            confirmText: "Alterar",
            title: `Deseja realmente alterar o cartão utilizado para doações futuras para "${cardTitles[selectedIndx]}"?`
          });
        }}
      >Definir como padrão</Button>
      <Button
        disabled={
          (selectedIndx === user.defaultCreditCardIndex && hasSubscriptionWithCard(user)) ||
          user.creditCards.length === 0
        }
        outlined={true}
        onClick={() => {
          setActionToConfirm({
            callback: async () => {
              const updates: Partial<User> = {
                defaultCreditCardIndex: selectedIndx > user.defaultCreditCardIndex
                  ? user.defaultCreditCardIndex
                  : user.defaultCreditCardIndex - 1,
                creditCards: user.creditCards.filter((card, indx) => indx !== selectedIndx),
              }
              setLoading("deleting-card");
              try {
                await userCollection.doc(user.id).update(updates);
                setUser({
                  ...user,
                  ...updates,
                });
              } catch(err) {
                console.error(err);
                errorHandler("internal-error");
              } finally {
                setLoading("deleting-card", false);
              }
            },
            cancelText: "Cancelar",
            confirmText: "Excluir",
            title: `Deseja realmente excluir o cartão "${cardTitles[selectedIndx]}"?`
          });
        }}
      >Excluir</Button>
    </>}
    <List>
      {cardTitles.map((title, indx) => <>
        <ListItem
          onClick={() => {
            setSelectedIndx(indx);
            setShowNewCardForms(false);
          }}
          selected={selectedIndx === indx}
        >
          <MaterialIcon icon="credit_card"/>
          <ListItemText primaryText={title + (indx === user.defaultCreditCardIndex ? " (Padrão)" : "")}/>
        </ListItem>
      </>)}
      <ListItem
        onClick={() => setShowNewCardForms(!showNewCardForms)}
        selected={false}
      >
        <MaterialIcon icon="add"/>
        <ListItemText primaryText="Adicionar novo cartão de crédito"/>
      </ListItem>
    </List>

    {showNewCardForms && <>
      <div className="l-new-card-forms-container">
        <NewCreditCard
          newCard={newCard}
          setNewCard={setNewCard}
          isTosaveAddress={isToSaveAddress}
          setIsTosaveAddress={setIsToSaveAddress}
        />
      </div>

      <Button
        outlined={true}
        disabled={disabledActions}
        onClick={async () => {
          setDisabledActions(true);
          const validNewCardResult = paymentGateway.validateNewCard(newCard);
          if (!validNewCardResult.success) {
            errorHandler(validNewCardResult.error);
            setDisabledActions(false);
            return;
          }
          const validNewCard = validNewCardResult.data;

          setLoading("add-new-card");
          try {
            const {number, month, year, name, document, billingAddress} = validNewCard;
            const company = paymentGateway.getCardCompany(validNewCard.number);
            const hash = await paymentGateway.getCardHash(validNewCard);
            const junoId = await functions.addCreditCard(hash);
            const newCreditCardArray: CreditCard[] = [...(user?.creditCards), {
              junoId,
              company,
              last4digits: paymentGateway.getCardLast4digits(number),
              expirationMonth: month,
              expirationYear: year,
              holderName: name,
              holderDocument: document,
              billingAddress: billingAddress as Address,
            }];
            const newCardIndx = newCreditCardArray.length - 1;
            const updates: Partial<User> = isToSaveAddress
              ? {
                creditCards: newCreditCardArray,
                defaultCreditCardIndex: newCardIndx,
                address: billingAddress,
              } : {
                creditCards: newCreditCardArray,
                defaultCreditCardIndex: newCardIndx,
              };
            await userCollection.doc(user?.id).update(updates);
            
            setUser({
              ...(user as User),
              ...updates,
            });
            setSelectedIndx(newCardIndx);
            setShowNewCardForms(false);
          } catch(err) {
            console.error(err);
            errorHandler("unable-to-add-card");
          } finally {
            setDisabledActions(false);
            setLoading("add-new-card", false);
          }
        }}
      >Adicionar</Button>
    </>}
  </>
};

export default CreditCards;