export type ErrorCode =
  | "invalid-amount"
  | "invalid-cpf"
  | "invalid-cnpj"
  | "invalid-email"
  | "invalid-name"
  | "invalid-password"
  | "invalid-alumni"
  | "existing-user"
  | "unable-to-crate-user"
  | "invalid-card-number"
  | "invalid-card-name"
  | "invalid-card-document"
  | "invalid-card-month"
  | "invalid-card-year"
  | "invalid-card-cvv"
  | "expired-card"
  | "card-not-accepted"
  | "unable-to-add-card"
  | "login-fail"
  | "no-user-to-email"
  | "wrong-password"
  | "auth-fail"
  | "invalid-payment-method"
  | "internal-error"
  | "conflict"
  | "privacy-policy-not-accepted"
  | "payment-gateway-unavailable"
  | "invalid-address-street"
  | "invalid-address-number"
  | "invalid-address-city"
  | "invalid-address-state"
  | "invalid-address-postal-code"
  | "invalid-address-district"
  | "empty-password"
  | "too-short-password"
  | "mismatch-password"
  | "unable-to-reset-password"
  | "payment-error";

export interface ErrorResult {
  success: false;
  error: ErrorCode;
}

export interface SuccessResult<D = undefined> {
  success: true;
  error?: null;
  data: D;
}

type Result<D = undefined> = ErrorResult | SuccessResult<D>;

export default Result;

const pleasePtBr = "por favor, verifique os dados inseridos.";
export const messagesMapPtBr = new Map<ErrorCode, string>([
  [
    "invalid-amount",
    `Quantia inválida, ${pleasePtBr}`,
  ],
  [
    "invalid-cpf",
    `CPF inválido, ${pleasePtBr}`,
  ],
  [
    "invalid-cnpj",
    `CNPJ inválido, ${pleasePtBr}`,
  ],
  [
    "invalid-email",
    `Email inválido, ${pleasePtBr}`,
  ],
  [
    "invalid-name",
    `Nome completo ou razão social inválido, ${pleasePtBr}`,
  ],
  [
    "invalid-password",
    `A senha digitada é inválida ou muito curta, ela deve conter pelo menos 6 carateres, ${pleasePtBr}`
  ],
  [
    "invalid-alumni",
    `O doador não pode ser tanto alumini como empresa, ${pleasePtBr}`,
  ],
  [
    "existing-user",
    "Doador já existente, por favor, tente fazer o login com o email digitado ou solicite a redefinição de senha.",
  ],
  [
    "unable-to-crate-user",
    "Desculpe, não foi possível criar o usuário no momento, por favor, tente novamente mais tarde.",
  ],
  [
    "invalid-card-number",
    `Número do cartão inválido, ${pleasePtBr}`,
  ],
  [
    "invalid-card-name",
    `Nome do titular inválido, ${pleasePtBr}`,
  ],
  [
    "invalid-card-document",
    `CPF ou CNPJ do titular inválido, ${pleasePtBr}`,
  ],
  [
    "invalid-card-month",
    `Mês de vencimento inválido, ${pleasePtBr}`,
  ],
  [
    "invalid-card-year",
    `Ano de vencimento inválido, ${pleasePtBr}`,
  ],
  [
    "invalid-card-cvv",
    `Código de segurança (CVV) inválido, esse código se encontra no verso do cartão, ${pleasePtBr}`,
  ],
  [
    "expired-card",
    "Cartão de crédito expirado, por favor, tente novamente com outro cartão ou método de pagamento.",
  ],
  [
    "card-not-accepted",
    "Cartão recusado pela operadora, por favor, tente novamente com outro cartão ou método de pagamento.",
  ],
  [
    "unable-to-add-card",
    "Desculpe, não foi possível adicionar o cartão no momento, por favor, tente novamente."
  ],
  [
    "login-fail",
    "Desculpe, ocorreu uma falha inesperada ao tentar realizar o login, por favor, tente novamente.",
  ],
  [
    "no-user-to-email",
    `Não há nenhum cadastro para o email digitado, ${pleasePtBr}`,
  ],
  [
    "wrong-password",
    `A senha digitada está incorreta, ${pleasePtBr}`,
  ],
  [
    "auth-fail",
    "Desculpe, ocorreu um erro de autenticação, por favor, faça o login novamente.",
  ],
  [
    "invalid-payment-method",
    `Método de pagamento ou cartão de crédito inválido, ${pleasePtBr}`,
  ],
  [
    "internal-error",
    "Desculpe, ocorreu um erro interno, por favor, tente novamente.",
  ],
  [
    "conflict",
    "Não foi possível criar um novo usuário, verifique se o número do CPF/CNPJ" +
    " está correto ou se já não foi utilizado anteriormente para a criação de outra conta." + 
    " Entre em contato com endowment@fm.usp.br caso necessário."
  ],
  [
    "privacy-policy-not-accepted",
    "Para continuar, é necessário aceitar os termos de uso e a política de privacidade",
  ],
  [
    "payment-gateway-unavailable",
    "Desculpe, a operadora de pagamentos está indisponível, tente novamente mais tarde."
  ],
  [
    "invalid-address-street",
    `Nome do endereço inválido, ${pleasePtBr}`,
  ],
  [
    "invalid-address-number",
    `Número do endereço inválido, ${pleasePtBr}`,
  ],
  [
    "invalid-address-city",
    `Nome da cidade inválido, ${pleasePtBr}`,
  ],
  [
    "invalid-address-state",
    `Nome do estado inválido, ${pleasePtBr}`,
  ],
  [
    "invalid-address-postal-code",
    `CEP ou código postal inválido, ${pleasePtBr}`,
  ],
  [
    "invalid-address-district",
    `Nome do bairro inválido, ${pleasePtBr}`,
  ],
  [
    "empty-password",
    "A senha não pode ser vazia."
  ],
  [
    "too-short-password",
    "A senha digitada é muito curta."
  ],
  [
    "mismatch-password",
    "A confirmação da senha não é igual a senha digitada."
  ],
  [
    "unable-to-reset-password",
    "Erro inesperado, por favor, repita o processo de recuperação de senha"
  ],
  [
    "payment-error",
    "Não foi possível realizar o pagamento.",
  ]
]);

export function getErrorMessagePtBr(errorCode?: string | null) {
  const message = messagesMapPtBr.get(errorCode as ErrorCode);
  if (message) {
    return message;
  } else {
    return "Desculpe, ocorreu um erro inesperado, por favor, tente novamente.";
  }
}
