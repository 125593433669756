import React, { FunctionComponent } from "react";
import "./AdminDrawer.scss";
import { app } from "firebase";
import Divider from "./../components/Divider";
import { Button } from "@material/react-button";
import LoadingSetter from "../LoadingSetter";
import List, { ListItem, ListItemText } from "@material/react-list";

export type AdminPage =
  | "dashboard"
  | "donnors"
  | "crm"
  | "guilds"
  | "campaigns";

export const AdminPageNamesPtBr = new Map<AdminPage, string>([
  ["dashboard", "Relatório Financeiro"],
  ["donnors", "Doadores"],
  ["crm", "CRM"],
  ["guilds", "Agremiações"],
  ["campaigns", "Campanhas"],
])

interface AdminDrawerProps {
  adminPage: AdminPage;
  setAdminPage: (page: AdminPage) => void;
  firebase: app.App;
  setLoading: LoadingSetter;
}

const AdminDrawer: FunctionComponent<AdminDrawerProps> = (props) => {
  const { firebase, setAdminPage, setLoading, adminPage } = props;

  return <div className="l-admin-drawer">
    <div>
      <h3>Olá, {firebase.auth().currentUser?.displayName}.</h3>
      <Button className="l-logout-button" onClick={async () => {
        setLoading("logout");
        await firebase.auth().signOut();
        window.location.replace("/");
      }}>Logout</Button>
    </div>

    <Divider />

    <List>
      <ListItem
        onClick={() => {
          setAdminPage("dashboard");
        }}
        selected={adminPage === "dashboard"}
      >
        <ListItemText primaryText={AdminPageNamesPtBr.get("dashboard")} />
      </ListItem>
      <ListItem
        onClick={() => {
          setAdminPage("donnors");
        }}
        selected={adminPage === "donnors"}
      >
        <ListItemText primaryText={AdminPageNamesPtBr.get("donnors")} />
      </ListItem>
      <ListItem
        onClick={() => {
          setAdminPage("crm");
        }}
        selected={adminPage === "crm"}
      >
        <ListItemText primaryText={AdminPageNamesPtBr.get("crm")} />
      </ListItem>
      <ListItem
        onClick={() => {
          setAdminPage("guilds");
        }}
        selected={adminPage === "guilds"}
      >
        <ListItemText primaryText={AdminPageNamesPtBr.get("guilds")} />
      </ListItem>
      <ListItem
        onClick={() => {
          setAdminPage("campaigns");
        }}
        selected={adminPage === "campaigns"}
      >
        <ListItemText primaryText={AdminPageNamesPtBr.get("campaigns")} />
      </ListItem>
    </List>

  </div>
}

export default AdminDrawer;

