import React, {FunctionComponent, useState} from "react";
import TextField, {HelperText, Input} from "@material/react-text-field";
import MaterialIcon from "@material/react-material-icon";
import Button from "@material/react-button";
import {ErrorCode} from "../../Result";
import { app } from "firebase";
import LoadingSetter from "../../LoadingSetter";

interface PasswordProps {
  code: string | null;
  firebase: app.App;
  setLoading: LoadingSetter;
  setErrorToDisplay: (error: ErrorCode | null) => void;
  setSuccessfulPasswordReset: (success: boolean) => void;
}


const Password: FunctionComponent<PasswordProps> = (props) => {
  const {
    code,
    firebase,
    setLoading,
    setErrorToDisplay,
    setSuccessfulPasswordReset
  } = props;
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");

  const handleSubmit = (): void => {
    if(password === "") {
      setErrorToDisplay("empty-password");
    } else if (password.length < 6) {
      setErrorToDisplay("too-short-password");
    } else if (password !== passwordRepeat) {
      setErrorToDisplay("mismatch-password");
    } else {
      setLoading("password-reset");
      firebase.auth().confirmPasswordReset(code as string, password).then(() => {
        setSuccessfulPasswordReset(true);
      }).catch((err: Error) => {
        setErrorToDisplay("unable-to-reset-password");
        console.error(err);
      }).finally(() => {
        setLoading("password-reset", false);
      });
    }
  }

  return <>
    <p>Configuração de senha</p>

    {code
      ?
      <>
        <TextField
          className="m-password-input"
          label="Nova senha"
          helperText={<HelperText>Digite a nova senha</HelperText>}
          trailingIcon={<MaterialIcon role="button" icon="lock"/>}
        >
          <Input
            type={"password"}
            value={password}
            onChange={(e: any) => setPassword(e.currentTarget.value)}
          />
        </TextField>

        <TextField
          className="m-password-input"
          label="Confirmação"
          helperText={<HelperText>Digite novamente a nova senha</HelperText>}
          trailingIcon={<MaterialIcon role="button" icon="lock"/>}
        >
          <Input
            type={"password"}
            value={passwordRepeat}
            onChange={(e: any) => setPasswordRepeat(e.currentTarget.value)}
          />
        </TextField>

        <Button
          className="m-submit-button"
          raised={true}
          outlined={true}
          onClick={handleSubmit}
        >Alterar</Button>
      </>
      :
      <>
        <p>Código de redefinição de senha ausente, por favor, repita o procedimento de redefinição de senha.</p>
      </>
    }
  </>
};

export default Password;