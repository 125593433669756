import React, {FunctionComponent, useState} from "react";
import {app} from "firebase";
import TextField, {Input, HelperText} from "@material/react-text-field";
import { Button } from "@material/react-button";
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from '@material/react-dialog';
import {Snackbar} from "@material/react-snackbar";
import LoadingSetter from "../../LoadingSetter";


interface LoginResetProps {
  firebase: app.App;
  loading: string[];
  setLoading: LoadingSetter;
  errorHandler: (msg: string) => void;
  className?: string;
}

export interface LoginButtonProps extends LoginResetProps {
  loginData: LoginData;
};

interface LoginProps {
  loginData: LoginData;
  setLoginData: (loginData: LoginData) => void;
}

export interface LoginData {
  email: string;
  password: string;
}

export const LoginButton: FunctionComponent<LoginButtonProps> = (props) => {
  const {firebase, loginData, loading, setLoading, errorHandler, className} = props;

  return <>
    <Button
      className={className}
      outlined={true}
      disabled={loading.includes("login")}
      onClick={async () => {
        setLoading("login");
        try {
          await firebase.auth().signInWithEmailAndPassword(loginData.email, loginData.password);
        } catch(err) {
          errorHandler(err.code);
        } finally{
          setLoading("login", false);
        }
      }}
    >Login</Button>
  </>
}

export interface LoginResetDialogProps extends LoginResetProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}; 

export const LoginResetDialog: FunctionComponent<LoginResetDialogProps> = (props) => {
  const {firebase, setLoading, errorHandler, isOpen, setIsOpen} = props;
  const [email, setEmail] = useState<string>("");
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  
  return <>
    <Dialog
      id="login-reset-dialog"
      onClose={async (action) => {
        if (action === "confirm") {
          setLoading("password-reset");
          try {
            await firebase.auth().sendPasswordResetEmail(email);
            setShowConfirmation(true);
          } catch(err) {
            console.error(err);
            errorHandler(err.code);
          } finally {
            setLoading("password-reset", false);
          }
        }
        setIsOpen(false);
      }}
      open={isOpen}>
      <DialogTitle>Redefinição de senha</DialogTitle>
      <DialogContent>
        <p>Por favor, digite o email para o envio do link para redefinição de senha:</p>
        <TextField
          label="Email"
        >
          <Input
            type="email"
            isValid={email
              ? email.includes("@")
              : true
            }
            value={email ? email : ""}
            onChange={(e: any) => setEmail(e.currentTarget.value)}
          />
        </TextField>
      </DialogContent>
      <DialogFooter>
        <DialogButton action="dismiss">Cancelar</DialogButton>
        <DialogButton action="confirm" isDefault>Redefinir</DialogButton>
      </DialogFooter>
    </Dialog>

    {showConfirmation &&
      <Snackbar
        message="Solicitação de redefinição de senha realizada com sucesso"
        actionText="Fechar"
        onClose={() => {
          setShowConfirmation(false);
        }}
      />
    }
  </>
}

const Login: FunctionComponent<LoginProps> = (props) => {
  const {loginData, setLoginData} = props;

  return <>
    <TextField
      label="email"
    >
      <Input
        type="email"
        value={loginData.email}
        onChange={(e: any) => {
          e.preventDefault();

          setLoginData(((loginData: LoginData) => ({
            ...loginData,
            email: e.currentTarget.value,
          })) as unknown as LoginData)
        }}
      />
    </TextField>

    <TextField
      label="senha"
    >
      <Input
        type="password"
        value={loginData.password}
        onChange={(e: any) => {
          e.preventDefault();

          setLoginData(((loginData: LoginData) => ({
            ...loginData,
            password: e.currentTarget.value,
          })) as unknown as LoginData)
        }}
      />
    </TextField>
  </>
}

export default Login;
