import React, {FunctionComponent, useState} from "react";
import { app } from "firebase";
import List, {ListItem, ListItemText} from "@material/react-list";
import MaterialIcon from "@material/react-material-icon";
import Donation from "../../../../entities/Donation";
import TextField, { Input } from "@material/react-text-field";
import User from "../../../../entities/User";
import { Payment as PaymentSelector, CreditCard } from "../../../../entities/Payment";

interface PaymentProps {
  firebase: app.App;
  selectedMethod: PaymentSelector;
  setSelectedMethod: (method: PaymentSelector) => void;
  donation: Partial<Donation>;
  user: User | null;
}

const getCardData = (card: CreditCard, indx: number): PaymentSelector => ({
  type: "CREDIT_CARD",
  cardIndx: indx,
  last4digits: card.last4digits,
  company: card.company,
})

const Payment: FunctionComponent<PaymentProps> = (props) => {
  const {selectedMethod, setSelectedMethod, donation, user} = props;
  const cardsTitles: string[] = user?.creditCards.map(card => `${card.company
    ? card.company.charAt(0).toUpperCase() + card.company.slice(1)
    : "Cartão"
  } final ${card.last4digits}`) || [];

  return <>
    <List>
      {donation.type === "SINGLE" &&
        <ListItem
          onClick={() => setSelectedMethod({type: "BOLETO"})}
          selected={selectedMethod?.type === "BOLETO"}
        >
          <MaterialIcon icon="attach_money"/>
          <ListItemText className="m-list-item-text" primaryText="Boleto bancário"/>
        </ListItem>
      }
      {
        cardsTitles.map((title, indx) =>
        <ListItem
          onClick={() => user && setSelectedMethod(getCardData(user?.creditCards[indx], indx))}
          selected={selectedMethod.type === "CREDIT_CARD" && selectedMethod.cardIndx === indx}
        >
          <MaterialIcon icon="credit_card"/>
          <ListItemText className="m-list-item-text" primaryText={title}/>
        </ListItem>)
      }
      <ListItem
        onClick={() => setSelectedMethod({type: "CREDIT_CARD", cardIndx: null})}
        selected={selectedMethod.type === "CREDIT_CARD" && selectedMethod.cardIndx === null}
      >
        {cardsTitles.length > 0
          ? <MaterialIcon icon="add"/>
          : <MaterialIcon icon="credit_card"/>
        }
        <ListItemText className="m-list-item-text" primaryText={cardsTitles.length > 0
          ? "Adicionar novo cartão de crédito"
          : "Cartão de crédito"
        }/>
      </ListItem>
    </List>
  </>
}

export default Payment;
