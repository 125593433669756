import { cpf, cnpj } from "cpf-cnpj-validator";
import config from "../config";
import Result from "../Result";
import Address from "../../entities/Address";

export interface NewCard {
  number: string;
  name: string;
  month: string;
  year: string;
  cvv: string;
  document: string;
  billingAddress: Partial<Address>;
}

class Juno {
  private checkout: IDirectCheckout;

  constructor() {
    const { junoPublicToken, useSandbox } = config;
    this.checkout = new window.DirectCheckout(junoPublicToken, !useSandbox);
  }

  private sanitizeCardNumber = (cardNumber: any): Result<string> => {
    const data =
      typeof cardNumber === "string" &&
      cardNumber.replace(/\s/g, "").replace(/\./g, "");

    return typeof data === "string"
      ? { success: true, data }
      : { success: false, error: "invalid-card-number" };
  };

  healthCheck = (): Result => {
    return this.checkout
      ? {success: true, data: undefined}
      : {success: false, error: "payment-gateway-unavailable"};
  }

  validateCardMonth = (input?: string): boolean => {
    const month = (new Number(input)).valueOf();
    if (isNaN(month)) {
      return false;
    } else {
      return month > 0 && month <= 12;
    }
  }
  
  validateCardYear = (input?: string): boolean => {
    const year = (new Number(input)).valueOf();
    if (isNaN(year)) {
      return false;
    } else {
      return year + 2000 >= (new Date()).getFullYear() && year < 100;
    }
  }
  
  validateNewCard = (input: Partial<NewCard>): Result<NewCard> => {
    const { name, document, month, year, cvv, billingAddress } = input;
    const sanitizingResult = this.sanitizeCardNumber(input.number);
    if (!sanitizingResult.success) {
      return sanitizingResult;
    }
    const cardNumber = sanitizingResult.data;

    if (
      !cardNumber ||
      cardNumber.length < 8 ||
      cardNumber.length > 19 ||
      isNaN(new Number(cardNumber).valueOf()) ||
      !this.checkout.isValidCardNumber(cardNumber)
    ) {
      return { success: false, error: "invalid-card-number" };
    }
    if (!name || name.trim().length < 2) {
      return { success: false, error: "invalid-card-name" };
    }
    if (!document || !(cpf.isValid(document) || cnpj.isValid(document))) {
      return {success: false, error: "invalid-card-document"};
    }
    if (!month || !this.validateCardMonth(month)) {
      return {success: false, error: "invalid-card-month"};
    }
    if (!year || !this.validateCardYear(year)) {
      return {success: false, error: "invalid-card-year"};
    }
    const fullYear = `${2000 + (new Number(year)).valueOf()}`;
    if (!cvv || !this.checkout.isValidSecurityCode(cardNumber, cvv)) {
      return {success: false, error: "invalid-card-cvv"};
    }
    if (!this.checkout.isValidExpireDate(month, fullYear)) {
      console.log(month, year)
      return {success: false, error: "invalid-card-cvv"};
    }
    if (!this.checkout.isValidCardData({
      cardNumber: cardNumber,
      holderName: name,
      securityCode: cvv,
      expirationMonth: month,
      expirationYear: fullYear,
    }, (err) => console.error(err))) {
      return {success: false, error: "card-not-accepted"}
    }
    if (!billingAddress?.street?.trim()) {
      return {success: false, error: "invalid-address-street"};
    }
    if (!billingAddress?.number?.trim()) {
      return {success: false, error: "invalid-address-number"};
    }
    if (!billingAddress?.postalCode?.match(/^[0-9]{8}$/g)) {
      return {success: false, error: "invalid-address-postal-code"};
    }
    if (!billingAddress?.city?.trim()) {
      return {success: false, error: "invalid-address-city"};
    }
    if (!billingAddress?.state?.trim()) {
      return {success: false, error: "invalid-address-state"};
    }

    return {success: true, data: { number: cardNumber, name, document, month, year, cvv, billingAddress }};
  };

  getCardCompany = (cardNumber: string) => this.checkout.getCardType(cardNumber);

  getCardHash = async (newCard: NewCard) => new Promise<string>((resolve, reject) => {
    const { number, name, month, year, cvv } = newCard;
    const cardData: IJunoCardData = {
      cardNumber: number,
      holderName: name,
      securityCode: cvv,
      expirationMonth: month,
      expirationYear: `20${year}`
    };

    this.checkout.getCardHash(cardData, resolve, reject);
  });

  getCardLast4digits = (cardNumber: string) => cardNumber.substring(cardNumber.length - 4);
  
  downloadBoleto = async (uri: string): Promise<void> => {
    const request = new Request(uri);
    const response = await fetch(request);
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);
    const anchor = document.createElement("a");

    anchor.id = "boleto-downloader";
    anchor.href = objectUrl;
    anchor.download = "boleto-endownment-fmusp.pdf"
    anchor.type = "application/pdf";
    anchor.target = "_blank";
    anchor.rel = "noreferrer noopener";
    anchor.style.display = "none";
    
    document.body.appendChild(anchor);
    anchor.click();
    document.removeChild(anchor);
  }
}

const paymentGateway = new Juno();

export default paymentGateway;
